import { GUARD } from '@tenant/utils/constants'
import { isActive } from '@tenant/core/feature'

const routes = [
    {
        name: 'employees.overview',
        path: '/employees/overview',
        redirect: {
            name: 'employees.overview.list',
        },
        children: [
            {
                name: 'employees.overview.list',
                path: 'list',
                component: () => import('./pages/employees-overview-list.vue'),
                meta: {
                    noPadding: true,
                },
            },
            {
                name: 'employees.overview.create',
                path: 'create',
                component: () =>
                    import('./pages/employees-overview-create.vue'),
            },
            {
                name: 'employees.overview.edit',
                path: ':id',
                meta: {
                    noPadding: true,
                },
                component: () =>
                    import('./pages/employees-overview-detail-v2.vue'),
                redirect: {
                    name: 'employees.overview.edit.detail',
                },
                children: [
                    {
                        name: 'employees.overview.edit.detail',
                        path: 'detail',
                        meta: {
                            noPadding: true,
                        },
                        component: () =>
                            import(
                                './components/payroll/employees-detail-personal-details.vue'
                            ),
                    },
                    {
                        name: 'employees.overview.edit.information',
                        path: 'information',
                        meta: {
                            noPadding: true,
                        },
                        component: () =>
                            import(
                                './components/payroll/employees-detail-information.vue'
                            ),
                    },
                    {
                        name: 'employees.overview.edit.taxes',
                        path: 'taxes',
                        meta: {
                            noPadding: true,
                        },
                        component: () =>
                            import(
                                './components/payroll/employees-detail-taxes.vue'
                            ),
                    },
                ],
            },
        ],
    },
    {
        name: 'employees.leave',
        path: '/employees/leave',
        redirect: {
            // TODO: Add the correct path
            name: 'employees.leave.list',
        },

        // TODO: Add the correct children
        children: [
            {
                name: 'employees.leave.list',
                path: 'list',
                component: () => import('./pages/employees-overview-list.vue'),
                meta: {
                    noPadding: true,
                },
            },
            {
                name: 'employees.leave.create',
                path: 'create',
                component: () =>
                    import('./pages/employees-overview-create.vue'),
            },
            {
                name: 'employees.leave.edit',
                path: ':id',
                component: () => import('./pages/employees-overview-edit.vue'),
            },
        ],
    },
    {
        name: 'employees.taxes-and-filings',
        path: '/employees/taxes-and-filings',
        redirect: {
            // TODO: Add the correct path
            name: 'employees.taxes-and-filings.list',
        },

        // TODO: Add the correct children
        children: [
            {
                name: 'employees.taxes-and-filings.list',
                path: 'list',
                component: () => import('./pages/employees-overview-list.vue'),
                meta: {
                    noPadding: true,
                },
            },
            {
                name: 'employees.taxes-and-filings.create',
                path: 'create',
                component: () =>
                    import('./pages/employees-overview-create.vue'),
            },
            {
                name: 'employees.taxes-and-filings.edit',
                path: ':id',
                component: () => import('./pages/employees-overview-edit.vue'),
            },
        ],
    },
    {
        name: 'employees.pay-employees',
        path: '/employees/pay-employees',
        redirect: {
            // TODO: Add the correct path
            name: 'employees.pay-employees.list',
        },

        // TODO: Add the correct children
        children: [
            {
                name: 'employees.pay-employees.list',
                path: 'list',
                component: () => import('./pages/employees-overview-list.vue'),
                meta: {
                    noPadding: true,
                },
            },
            {
                name: 'employees.pay-employees.create',
                path: 'create',
                component: () =>
                    import('./pages/employees-overview-create.vue'),
            },
            {
                name: 'employees.pay-employees.edit',
                path: ':id',
                component: () => import('./pages/employees-overview-edit.vue'),
            },
        ],
    },
    ...(isActive('FEATURE_FLAG_1078')
        ? [
              {
                  name: 'employees.payroll',
                  path: '/employees/payroll',
                  redirect: {
                      name: 'employees.payroll.absences',
                  },
                  children: [
                      {
                          name: 'employees.payroll.absences',
                          path: 'absences',
                          component: () =>
                              import('./pages/employees-payroll-absences.vue'),
                      },
                      {
                          name: 'employees.payroll.compensations',
                          path: 'compensations',
                          component: () =>
                              import(
                                  './pages/employees-payroll-compensations.vue'
                              ),
                      },
                  ],
              },
          ]
        : []),
]

export default {
    routes,
    meta: { layout: 'dashboard-layout', guard: GUARD.TENANT },
}
