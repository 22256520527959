<template>
    <div class="p-6">
        <employees-detail-personal-data-form>
            <template #content>
                <form-control-group-row>
                    <form-text-input
                        v-model="phone1"
                        :error-message="phone1Error"
                        :label="
                            $t(
                                'employeesOverview.create.personalData.phone1.label'
                            )
                        "
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <div
                    v-if="!isPhone2"
                    class="flex w-full cursor-pointer flex-row items-start gap-2"
                    @click="isPhone2 = true"
                >
                    <base-icon
                        variant="hardPrimary"
                        name="line-icons:general:plus"
                        class="cursor-pointer"
                        size="sm"
                    />
                    <div class="text-sm font-normal text-primary-700">
                        {{
                            $t(
                                'employeesOverview.create.personalData.phone2.add'
                            )
                        }}
                    </div>
                </div>

                <form-control-group-row v-else>
                    <form-text-input
                        v-model="phone2"
                        :label="
                            $t(
                                'employeesOverview.create.personalData.phone2.label'
                            )
                        "
                        :disabled="disabled"
                    />
                </form-control-group-row>
            </template>
        </employees-detail-personal-data-form>
    </div>
</template>

<script setup>
defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})
const isPhone2 = ref(false)
const { value: phone1, errorMessage: phone1Error } = useField('primary_phone')
const { value: phone2 } = useField('secondary_phone')
</script>
