<template>
    <base-modal close :show="show" @modal-close="closeModal" size="xl">
        <template #modal-header>
            <h4 class="text-lg">
                {{ $t('employeesOverview.workingHoursModal.title') }}
            </h4>
        </template>

        <template #default>
            <div class="-mx-6 border-t pb-6"></div>

            <form-control-group>
                <form-control-group-row>
                    <form-number-input
                        v-model="monday"
                        :precision="1"
                        :label="
                            $t(
                                'employeesOverview.workingHoursModal.monday.label'
                            )
                        "
                        size="sm"
                    />

                    <form-number-input
                        v-model="tuesday"
                        :precision="1"
                        :label="
                            $t(
                                'employeesOverview.workingHoursModal.tuesday.label'
                            )
                        "
                        size="sm"
                    />

                    <form-number-input
                        v-model="wednesday"
                        :precision="1"
                        :label="
                            $t(
                                'employeesOverview.workingHoursModal.wednesday.label'
                            )
                        "
                        size="sm"
                    />

                    <form-number-input
                        v-model="thursday"
                        :precision="1"
                        :label="
                            $t(
                                'employeesOverview.workingHoursModal.thursday.label'
                            )
                        "
                        size="sm"
                    />

                    <form-number-input
                        v-model="friday"
                        :precision="1"
                        :label="
                            $t(
                                'employeesOverview.workingHoursModal.friday.label'
                            )
                        "
                        size="sm"
                    />

                    <form-number-input
                        v-model="saturday"
                        :precision="1"
                        :label="
                            $t(
                                'employeesOverview.workingHoursModal.saturday.label'
                            )
                        "
                        size="sm"
                    />

                    <form-number-input
                        v-model="sunday"
                        :precision="1"
                        :label="
                            $t(
                                'employeesOverview.workingHoursModal.sunday.label'
                            )
                        "
                        size="sm"
                    />

                    <form-number-input
                        v-model="week"
                        :precision="1"
                        :label="$t('employeesOverview.workingHoursModal.week')"
                        size="sm"
                        disabled
                    />
                </form-control-group-row>
            </form-control-group>
        </template>

        <template #modal-footer>
            <div class="flex gap-3 pt-9">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button full-width @click="onSubmit">
                    {{ $t('employeesOverview.workingHoursModal.actions.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close'])

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    hours: {
        type: Number,
        default: 0,
    },
})

const { value: monday } = useField(
    'work_information.weekly_working_times.monday'
)
const { value: tuesday } = useField(
    'work_information.weekly_working_times.tuesday'
)
const { value: wednesday } = useField(
    'work_information.weekly_working_times.wednesday'
)
const { value: thursday } = useField(
    'work_information.weekly_working_times.thursday'
)
const { value: friday } = useField(
    'work_information.weekly_working_times.friday'
)
const { value: saturday } = useField(
    'work_information.weekly_working_times.saturday'
)
const { value: sunday } = useField(
    'work_information.weekly_working_times.sunday'
)

const week = computed(() => {
    return (
        Number(monday.value ?? 0) +
        Number(tuesday.value ?? 0) +
        Number(wednesday.value ?? 0) +
        Number(thursday.value ?? 0) +
        Number(friday.value ?? 0) +
        Number(saturday.value ?? 0) +
        Number(sunday.value ?? 0)
    )
})

onMounted(() => {
    if (week.value != props.hours) setWorkTime(props.hours)
})

watch(
    () => props.hours,
    (value) => {
        if (week.value != value) setWorkTime(value)
    }
)

const setWorkTime = (value) => {
    let hoursPerWorkDay = value / 5
    let hoursPerWeekendDay = 0

    if (hoursPerWorkDay > 8) {
        hoursPerWorkDay = 8
        hoursPerWeekendDay = (value - 40) / 2

        saturday.value = hoursPerWeekendDay
        sunday.value = hoursPerWeekendDay
    } else {
        saturday.value = 0
        sunday.value = 0
    }

    monday.value = hoursPerWorkDay
    tuesday.value = hoursPerWorkDay
    wednesday.value = hoursPerWorkDay
    thursday.value = hoursPerWorkDay
    friday.value = hoursPerWorkDay
}

const closeModal = () => {
    emit('modal-close')
}

const onSubmit = () => {
    emit('modal-save', week.value)
}
</script>
