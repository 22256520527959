<template>
    <form-single-select
        :label="
            $t(
                'employeesOverview.detail.form.employeeInformation.ghsInsuranceCategory'
            )
        "
        :error-message="errorMessage"
        :options="ghsCategories"
        :disabled="disabled"
        :block="block"
        :size="size"
        :model-value="category"
        @change="onCategoryChange"
    >
        <template #option-label="{ item, inputValue }">
            <div class="flex flex-col">
                <span
                    class="mb-2"
                    v-html="getCategoryItemLabel(item, inputValue)"
                />
                <span class="text-sm text-gray-500">
                    {{ getCategoryItemDescription(item.value) }}
                </span>
            </div>
        </template>
    </form-single-select>
</template>
<script setup>
const emitter = defineEmits(['change'])

defineProps({
    block: {
        type: Boolean,
        default: true,
    },
    size: {
        type: String,
        default: 'md',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    category: {
        type: String,
        default: '',
    },
    errorMessage: {
        type: String,
        default: '',
    },
})
const { t } = useI18n()
const ghsCategories = ref([
    {
        value: 'a',
        label: t(
            'employeesOverview.detail.form.employeeInformation.ghs.a.label'
        ),
    },
    {
        value: 'f',
        label: t(
            'employeesOverview.detail.form.employeeInformation.ghs.f.label'
        ),
    },
    {
        value: 'j',
        label: t(
            'employeesOverview.detail.form.employeeInformation.ghs.j.label'
        ),
    },
    {
        value: 'l',
        label: t(
            'employeesOverview.detail.form.employeeInformation.ghs.l.label'
        ),
    },
])

const getCategoryItemDescription = (category) => {
    switch (category) {
        case 'a':
            return t(
                'employeesOverview.detail.form.employeeInformation.ghs.a.description'
            )
        case 'f':
            return t(
                'employeesOverview.detail.form.employeeInformation.ghs.f.description'
            )
        case 'j':
            return t(
                'employeesOverview.detail.form.employeeInformation.ghs.j.description'
            )
        case 'l':
            return t(
                'employeesOverview.detail.form.employeeInformation.ghs.l.description'
            )
        default:
            return ''
    }
}

const getCategoryItemLabel = (item, inputValue) => {
    const regex = new RegExp(inputValue, 'gi')
    const matched = item.label.match(regex)

    if (!matched) {
        return item.label
    }

    return item.label.replace(
        regex,
        (match) => `<span class="font-semibold">${match}</span>`
    )
}

const onCategoryChange = (value) => {
    emitter('change', value)
}
</script>
