<template>
    <form-control-group>
        <!-- Type -->
        <form-single-select
            label="employeesPayroll.absences.absenceModal.general.type.label"
            :model-value="absenceType"
            :options="absenceTypeOptions"
        />

        <!-- Reason -->
        <form-single-select
            label="employeesPayroll.absences.absenceModal.general.reason.label"
            :model-value="absenceReason"
            :options="absenceReasonOptions"
        />

        <!-- From - Until -->
        <form-control-group-row>
            <!-- From -->
            <form-date-picker
                v-model="absenceFrom"
                label="employeesPayroll.absences.absenceModal.general.from.label"
                single
                auto-apply
            />

            <!-- Until -->
            <form-date-picker
                v-model="absenceUntil"
                label="employeesPayroll.absences.absenceModal.general.until.label"
                single
                auto-apply
            />
        </form-control-group-row>

        <!-- Checkbox options -->
        <div class="flex flex-col gap-2.5">
            <form-checkbox
                v-model="workingFirstDay"
                :text="
                    $t(
                        'employeesPayroll.absences.absenceModal.general.workingFirstDay'
                    )
                "
            />
            <form-checkbox
                v-model="selfReported"
                :text="
                    $t(
                        'employeesPayroll.absences.absenceModal.general.selfReported'
                    )
                "
            />
            <form-checkbox
                v-model="diagnosedByDoctor"
                :text="
                    $t(
                        'employeesPayroll.absences.absenceModal.general.diagnosedByDoctor'
                    )
                "
                :disabled="!selfReported"
            />
        </div>
    </form-control-group>
</template>

<script setup>
const { t } = useI18n()
const emit = defineEmits(['set-absence'])

const props = defineProps({
    absence: {
        type: Object,
        default: null,
    },
})

const { value: absenceType } = useField('absenceType')
const { value: absenceReason } = useField('absenceReason')
const { value: absenceFrom } = useField('absenceFrom')
const { value: absenceUntil } = useField('absenceUntil')
const { value: workingFirstDay } = useField('workingFirstDay')
const { value: selfReported } = useField('selfReported')
const { value: diagnosedByDoctor } = useField('diagnosedByDoctor')

onMounted(() => {
    if (props.absence) {
        absenceType.value = props.absence.type
        absenceReason.value = props.absence.reason
        absenceFrom.value = props.absence.periodStart
        absenceUntil.value = props.absence.periodEnd
        workingFirstDay.value = props.absence.workingFirstDay
        selfReported.value = props.absence.selfReported
        diagnosedByDoctor.value = props.absence.diagnosedByDoctor
    }
})

watch(
    () => [
        absenceType.value,
        absenceReason.value,
        absenceFrom.value,
        absenceUntil.value,
        workingFirstDay.value,
        selfReported.value,
        diagnosedByDoctor.value,
    ],
    () => {
        emit('set-absence', {
            type: absenceType.value,
            name: t(
                'employeesPayroll.absences.absenceModal.general.optionLabel'
            ),
            reason: absenceReason.value,
            periodStart: absenceFrom.value,
            periodEnd: absenceUntil.value,
            days: workingFirstDay.value ? 0.5 : 0,
            workingFirstDay: workingFirstDay.value,
            selfReported: selfReported.value,
            diagnosedByDoctor: diagnosedByDoctor.value,
        })
    }
)

const absenceTypeOptions = ref([
    {
        value: 'general',
        label: 'General',
    },
])

const absenceReasonOptions = ref([
    {
        value: 'reason1',
        label: 'Reason 1',
    },
])
</script>
