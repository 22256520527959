<template>
    <base-modal close :show="show" size="lg" @modal-close="closeModal">
        <template #modal-header>
            <div class="flex items-center gap-1">
                <base-button
                    v-if="modalStep == 2"
                    @click="onReturn"
                    variant="link-gray"
                    :has-padding="false"
                >
                    <base-icon
                        name="line-icons:arrows:chevron-left"
                        variant="inherit"
                        size="lg"
                    />
                </base-button>

                <h4 class="text-lg">
                    {{
                        modalTitle
                            ? modalTitle
                            : $t(
                                  'employeesPayroll.compensations.addCompensationModal.general.title'
                              )
                    }}
                </h4>
            </div>
        </template>

        <!-- Search compensations -->
        <employees-payroll-compensations-modal-search-step
            v-if="modalStep == 1"
            @compensation-select="(value) => onSelectCompensation(value)"
        />

        <!-- 2nd step -->
        <div v-if="modalStep == 2">
            <employees-payroll-compensations-bonus-premium-modal
                v-if="modalName == 'bonus-premium'"
            />
            <employees-payroll-compensations-down-advance-modal
                v-if="modalName == 'down-advance'"
            />
            <employees-payroll-compensations-overtime-payment-modal
                v-if="modalName == 'overtime-payment'"
                :employee-name="employeeName"
            />
            <employees-payroll-compensations-pension-plan-modal
                v-if="modalName == 'pension-plan'"
                :employee-name="employeeName"
            />
            <employees-payroll-compensations-holiday-125-modal
                v-if="modalName == 'holiday-125'"
                :employee-name="employeeName"
            />
            <employees-payroll-compensations-holiday-150-modal
                v-if="modalName == 'holiday-150'"
                :employee-name="employeeName"
            />
        </div>

        <!-- Footer -->
        <template #modal-footer>
            <div class="mt-3 flex justify-end gap-3">
                <!-- Cancel button -->
                <base-button outline variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>

                <!-- Add button -->
                <base-button
                    v-if="modalStep == 2"
                    outline
                    variant="default"
                    @click="closeModal"
                >
                    {{
                        $t(
                            'employeesPayroll.compensations.addCompensationModal.add'
                        )
                    }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const modalStep = ref(1)
const modalName = ref('')
const modalTitle = ref('')

const emit = defineEmits(['modal-close'])

defineProps({
    show: {
        type: Boolean,
        default: false,
    },

    employeeName: {
        type: String,
        default: '',
    },
})

const closeModal = () => {
    modalStep.value = 1
    emit('modal-close')
}

const onReturn = () => {
    modalStep.value -= 1
}

const onSelectCompensation = (value) => {
    modalName.value = value.name
    modalTitle.value = value.title
    modalStep.value += 1
}
</script>
