<template>
    <base-modal close :show="show" @modal-close="closeModal" size="xl">
        <template #modal-header>
            <h4 class="text-lg">
                {{ $t('employeesOverview.vacationModal.title') }}
            </h4>
        </template>

        <template #default>
            <div class="-mx-6 border-t pb-6"></div>

            <base-alert
                v-if="showAlert"
                class="mb-5"
                variant="gray"
                :description="
                    $t('employeesOverview.vacationModal.alert.description', {
                        workingDays: workingDays,
                        days: minDays,
                    })
                "
            >
                <template #after-content>
                    <div class="pt-3">
                        <base-button
                            variant="default"
                            outline
                            size="sm"
                            @click="onTakeOver"
                        >
                            {{
                                $t(
                                    'employeesOverview.vacationModal.alert.button'
                                )
                            }}
                            <base-icon
                                name="line-icons:arrows:arrow-right"
                                variant="gray"
                            />
                        </base-button>
                    </div>
                </template>
            </base-alert>

            <form-control-group>
                <form-control-group-row>
                    <!-- Contractually regulated annual leave -->
                    <div class="flex w-1/2 items-end">
                        <form-number-input
                            class="[&_input]:rounded-r-none"
                            v-model="regulatedAnnualLeave"
                            :error-message="regulatedAnnualLeaveError"
                            :label="
                                $t(
                                    'employeesOverview.vacationModal.regulatedAnnualLeave.label'
                                )
                            "
                            :placeholder="
                                $t(
                                    'employeesOverview.vacationModal.regulatedAnnualLeave.placeholder',
                                    {
                                        days: minDays,
                                    }
                                )
                            "
                        />
                        <div
                            class="flex items-center rounded-r-lg border border-l-0 border-gray-300 px-4 py-3 text-sm"
                        >
                            {{
                                $t(
                                    'employeesOverview.vacationModal.regulatedAnnualLeave.suffix'
                                )
                            }}
                        </div>
                    </div>

                    <!-- Vacation already taken in 2023 -->
                    <div class="flex w-1/2 items-end">
                        <form-number-input
                            class="[&_input]:rounded-r-none"
                            v-model="alreadyTaken"
                            :error-messages="alreadyTakenError"
                            :label="
                                $t(
                                    'employeesOverview.vacationModal.alreadyTaken.label',
                                    {
                                        year: currentDate.getFullYear(),
                                    }
                                )
                            "
                        />
                        <div
                            class="flex items-center rounded-r-lg border border-l-0 border-gray-300 px-4 py-3 text-sm"
                        >
                            {{
                                $t(
                                    'employeesOverview.vacationModal.alreadyTaken.suffix'
                                )
                            }}
                        </div>
                    </div>
                </form-control-group-row>

                <!-- Calculated vactaion collapse -->
                <base-collapse>
                    <template #header-content>
                        <div
                            class="flex w-full items-center justify-between text-sm"
                        >
                            <span>
                                {{
                                    $t(
                                        'employeesOverview.vacationModal.calculatedVacationCollapse',
                                        {
                                            year: currentDate.getFullYear(),
                                        }
                                    )
                                }}
                            </span>

                            <span class="pt-2.5 text-lg font-bold">
                                {{ annualLeaveDaysActual }}
                                {{ $t('employeesOverview.vacationModal.days') }}
                            </span>
                        </div>
                    </template>

                    <div class="-ml-7 flex items-center justify-between pt-2.5">
                        <div class="text-sm">
                            <span class="font-semibold">
                                {{
                                    $t(
                                        'employeesOverview.vacationModal.contractualAnnualLeave',
                                        {
                                            days: minDays,
                                        }
                                    )
                                }}
                            </span>
                            <span class="pl-2.5 text-gray-500">
                                {{ contractualMonths }}
                            </span>
                        </div>

                        <span class="text-sm text-gray-500">
                            {{ calculatedAnnualLeaveDaysActual }}
                            {{ $t('employeesOverview.vacationModal.days') }}
                        </span>
                    </div>
                </base-collapse>
            </form-control-group>
        </template>

        <template #modal-footer>
            <div class="flex gap-3 pt-9">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button full-width @click="onSubmit">
                    {{ $t('employeesOverview.vacationModal.actions.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close'])

const showAlert = ref(true)

const { value: regulatedAnnualLeave, errorMessage: regulatedAnnualLeaveError } =
    useField('vacation_information.annual_leave_days_in_year')

const { value: minDays } = useField('vacation_information.min_days')
const { value: alreadyTaken, errorMessages: alreadyTakenError } = useField(
    'vacation_information.days_taken'
)
const { value: annualLeaveDaysActual } = useField(
    'vacation_information.annual_leave_days_actual'
)
const { value: calculatedAnnualLeaveDaysActual } = useField(
    'vacation_information.calculated_annual_leave_days_actual'
)

const currentDate = new Date()
const workingDays = ref('Mon - Fri')

minDays.value = 20

const contractualMonths = ref('September - Dezember')

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
})

const onTakeOver = () => {
    regulatedAnnualLeave.value = minDays.value
    showAlert.value = false
}

const closeModal = () => {
    emit('modal-close')
}

const onSubmit = () => {
    emit('modal-close')
}
</script>
