<template>
    <form-control-group>
        <div>
            <!-- For -->
            <p class="mb-2.5 text-sm">
                {{ $t('employeesPayroll.absences.absenceModal.forLabel') }}
                {{ employeeName }}
            </p>

            <!-- Note -->
            <p class="text-sm">
                <span class="font-semibold">
                    {{ $t('employeesPayroll.absences.absenceModal.noteLabel') }}
                </span>
                {{
                    $t('employeesPayroll.absences.absenceModal.dualStudy.note')
                }}
            </p>
        </div>

        <!-- From - Until -->
        <form-control-group-row>
            <!-- From -->
            <form-date-picker
                v-model="absenceFrom"
                :label="
                    $t(
                        'employeesPayroll.absences.absenceModal.dualStudy.from.label'
                    )
                "
                single
                auto-apply
            />

            <!-- Until -->
            <form-date-picker
                v-model="absenceUntil"
                :label="
                    $t(
                        'employeesPayroll.absences.absenceModal.dualStudy.until.label'
                    )
                "
                single
                auto-apply
            />
        </form-control-group-row>
    </form-control-group>
</template>

<script setup>
const { t } = useI18n()
const emit = defineEmits(['set-absence'])

const props = defineProps({
    employeeName: {
        type: String,
    },

    absence: {
        type: Object,
        default: null,
    },
})

const { value: absenceFrom } = useField('absenceFrom')
const { value: absenceUntil } = useField('absenceUntil')

onMounted(() => {
    if (props.absence) {
        absenceFrom.value = props.absence.periodStart
        absenceUntil.value = props.absence.periodEnd
    }
})

watch(
    () => [absenceFrom.value, absenceUntil.value],
    () => {
        emit('set-absence', {
            type: 'dual-study',
            name: t(
                'employeesPayroll.absences.absenceModal.dualStudy.optionLabel'
            ),
            periodStart: absenceFrom.value,
            periodEnd: absenceUntil.value,
            days: 0,
        })
    }
)
</script>
