<template>
    <form-control-group>
        <form-control-group-row>
            <!-- Quantity -->
            <div class="w-1/2">
                <form-number-input
                    v-model="compensationQuantity"
                    :label="
                        $t(
                            'employeesPayroll.compensations.addCompensationModal.bonusPremium.quantity.label'
                        )
                    "
                    size="sm"
                    @change="onChange()"
                />
            </div>

            <!-- Amount -->
            <div class="flex w-1/2 items-end">
                <form-number-input
                    class="[&_input]:rounded-r-none"
                    v-model="compensationAmount"
                    :label="
                        $t(
                            'employeesPayroll.compensations.addCompensationModal.bonusPremium.amount.label'
                        )
                    "
                    size="sm"
                    @change="onChange()"
                />
                <div
                    class="flex items-center rounded-r-lg border border-l-0 border-gray-300 px-4 py-2.5 text-sm"
                >
                    {{ currency.iso }}
                </div>
            </div>
        </form-control-group-row>

        <!-- Payment Interval -->
        <form-single-select
            :label="
                $t(
                    'employeesPayroll.compensations.addCompensationModal.bonusPremium.paymentInterval.label'
                )
            "
            :model-value="compensationPaymentInterval"
            :options="compensationPaymentIntervalOptions"
        />

        <!-- Total -->
        <div
            class="flex justify-between gap-3 rounded-lg bg-gray-50 px-2.5 py-5 text-lg font-semibold"
        >
            <span>
                {{
                    $t(
                        'employeesPayroll.compensations.addCompensationModal.bonusPremium.totalLabel'
                    )
                }}
            </span>

            <span>
                {{ $filters.symbolCurrency(total, currency) }}
            </span>
        </div>
    </form-control-group>
</template>

<script setup>
import { CURRENCIES } from '@tenant/utils/constants'

const currency = CURRENCIES.eur
const total = ref(0)

const { value: compensationQuantity } = useField('compensationQuantity')
const { value: compensationAmount } = useField('compensationAmount')
const { value: compensationPaymentInterval } = useField(
    'compensationPaymentInterval'
)

const onChange = () => {
    if (compensationQuantity.value && compensationAmount.value) {
        total.value = compensationQuantity.value * compensationAmount.value
    }
}

const compensationPaymentIntervalOptions = ref([
    {
        value: 'one-time',
        label: 'One time',
    },
])
</script>
