<template>
    <!-- Empty -->
    <div
        v-if="!dataSource?.total > 0"
        class="flex items-start justify-between rounded-lg border bg-white px-6 py-5 shadow-sm"
    >
        <div>
            <div class="mb-1 flex items-center gap-x-2 gap-y-1">
                <div class="text-lg font-medium">
                    {{ employee.firstname }} {{ employee.lastname }}
                </div>

                <base-badge
                    :label="
                        $t('employeesPayroll.absences.table.badge', {
                            year: new Date().getFullYear(),
                            days: vacationTaken.toFixed(1),
                        })
                    "
                />
            </div>

            <div class="text-sm text-gray-500">
                {{
                    $t('employeesPayroll.absences.table.empty', {
                        year: new Date().getFullYear(),
                    })
                }}
            </div>
        </div>

        <!-- Add button -->
        <base-button
            icon="line-icons:general:plus-circle"
            variant="secondary-color"
            @click="onToggleAbsenceModal(null)"
        >
            {{ $t('employeesPayroll.absences.table.addAbsences') }}
        </base-button>
    </div>
    <!-- Data grid -->
    <data-grid
        v-else
        :data-source="dataSource?.data"
        :columns="columns"
        :loading="loadingSearch"
        :empty-text="$t('employeesPayroll.absences.table.emptyText')"
        :empty-sub="$t('employeesPayroll.absences.table.emptySubText')"
        class="overflow-hidden shadow-sm [&>div:last-child]:hidden"
    >
        <template #header>
            <div class="flex w-full items-center justify-between gap-2">
                <div class="flex items-center gap-x-2 gap-y-1">
                    <div class="text-lg font-medium">
                        {{ employee.firstname }} {{ employee.lastname }}
                    </div>

                    <base-badge
                        :label="
                            $t('employeesPayroll.absences.table.badge', {
                                year: new Date().getFullYear(),
                                days: vacationTaken.toFixed(1),
                            })
                        "
                    />
                </div>

                <!-- Add button -->
                <base-button
                    icon="line-icons:general:plus-circle"
                    variant="secondary-color"
                    @click="onToggleAbsenceModal(null)"
                >
                    {{ $t('employeesPayroll.absences.table.addAbsences') }}
                </base-button>
            </div>
        </template>

        <template #column-absences="{ item }">
            {{ item.name }}
        </template>

        <template #column-period="{ item }">
            {{ item.periodStart }} - {{ item.periodEnd }}
        </template>

        <template #column-down-days="{ item }">
            {{
                $t('employeesPayroll.absences.table.downDaysCellText', {
                    days: item.days.toFixed(1),
                })
            }}
        </template>

        <template #column-action="{ item }">
            <div class="flex gap-1 px-2 py-1">
                <!-- Edit button -->
                <base-button
                    icon="line-icons:general:edit-01"
                    variant="tertiary-gray"
                    size="sm"
                    class="!border-0 !p-2.5"
                    @click="onToggleAbsenceModal(item)"
                />

                <!-- Delete -->
                <base-button
                    icon="line-icons:general:trash-01"
                    variant="tertiary-gray"
                    size="sm"
                    class="!border-0 !p-2.5"
                    @click="onToggleDeleteAbsence(item)"
                />
            </div>
        </template>

        <template #footer></template>
    </data-grid>

    <!-- Delete modal -->
    <base-delete-modal
        :show="showDeleteModal"
        size="md"
        @modal-close="onToggleDeleteAbsence"
        @confirm-delete="confirmDelete"
    >
        <template #icon>
            <base-feature-icon
                variant="danger"
                name="line-icons:general:trash-01"
            />
        </template>

        <template #title>
            {{ $t('employeesPayroll.absences.confirmDelete.title') }}
        </template>

        <template #description>
            {{
                $t('employeesPayroll.absences.confirmDelete.description', {
                    type: itemToRemove.title,
                    name: `${employee.firstname} ${employee.lastname}`,
                })
            }}
        </template>

        <p class="mt-2.5 text-sm text-gray-400">
            {{ $t('employeesPayroll.absences.table.periodLabel') }}
        </p>
        <p>{{ itemToRemove.periodStart }} - {{ itemToRemove.periodEnd }}</p>

        <p class="mt-2.5 text-sm text-gray-400">
            {{ $t('employeesPayroll.absences.table.downDaysLabel') }}
        </p>
        <p>
            {{
                $t('employeesPayroll.absences.confirmDelete.downDays', {
                    days: itemToRemove.days,
                })
            }}
        </p>
    </base-delete-modal>

    <!-- absence modal -->
    <employees-payroll-absences-modal
        :show="showAbsenceModal"
        :employee-name="`${employee.firstname} ${employee.lastname}`"
        :absence="currentEditAbsence"
        @modal-close="onToggleAbsenceModal"
        @modal-add="onAddAbsence"
        @modal-edit="onEditAbsence"
    />
</template>

<script setup>
const { t } = useI18n()
const { successNotify } = useNotification()

const vacationTaken = ref(4.5)

const props = defineProps({
    employee: {
        type: Object,
        required: true,
    },
})

const columns = [
    {
        property: 'absences',
        label: t('employeesPayroll.absences.table.absencesLabel'),
    },
    {
        property: 'period',
        label: t('employeesPayroll.absences.table.periodLabel'),
        tooltip: ' ',
    },
    {
        property: 'down-days',
        label: t('employeesPayroll.absences.table.downDaysLabel'),
    },
]

// Fech absences onMounted
const { useApiSearch } = useApiFactory('absences')

const { loading: loadingSearch, execute: executeSearch } = useApiSearch()
const dataSource = ref([])

onMounted(async () => {
    await getEmployeesAbsences(props.employee.id)
})

const getEmployeesAbsences = async (employeeId) => {
    executeSearch(employeeId)
        .then((response) => {
            dataSource.value = response
            throw new Error('')
        })
        .catch(() => {
            dataSource.value = {
                total: 1,
                data: [
                    {
                        id: new Date(),
                        type: 'general',
                        name: 'Illness',
                        periodStart: '01.09.2023',
                        periodEnd: '05.09.2023',
                        days: 4.5,
                    },
                ],
            }
        })
}

// Delete
const itemToRemove = ref(null)
const showDeleteModal = ref(false)

const onToggleDeleteAbsence = (item = null) => {
    showDeleteModal.value = !showDeleteModal.value
    itemToRemove.value = item
}

const confirmDelete = () => {
    // Find index of item, and then remove it from array
    const index = dataSource.value.data.findIndex((item) => {
        return item.id === itemToRemove.value.id
    })
    dataSource.value.data.splice(index, 1)

    dataSource.value.total--
    vacationTaken.value -= itemToRemove.value.days

    onToggleDeleteAbsence()

    successNotify({
        title: t('employeesPayroll.absences.deletedSuccessTitle'),
        text: t('employeesPayroll.absences.deletedSuccessText', {
            name: `${props.employee.firstname} ${props.employee.lastname}`,
        }),
    })

    // TODO: Remove from database
}

// Absence toggle modal
const showAbsenceModal = ref(false)
const currentEditAbsence = ref(null)

const onToggleAbsenceModal = (item = null) => {
    currentEditAbsence.value = item
    showAbsenceModal.value = !showAbsenceModal.value
}

// Add absence
const onAddAbsence = (absence) => {
    dataSource.value.total++
    dataSource.value.data.push(absence)
    vacationTaken.value += absence.days

    successNotify({
        title: t('employeesPayroll.absences.addSuccessTitle'),
        text: t('employeesPayroll.absences.addSuccessText', {
            name: `${props.employee.firstname} ${props.employee.lastname}`,
        }),
    })

    // TODO: Add to database
}

// Edit
const onEditAbsence = (absence) => {
    // // Find index of item, and then remove it from array
    const index = dataSource.value.data.findIndex((item) => {
        return item.id === absence.id
    })

    vacationTaken.value -= dataSource.value.data[index].days
    vacationTaken.value += absence.days

    dataSource.value.data[index] = absence

    successNotify({
        title: t('employeesPayroll.absences.editSuccessTitle'),
        text: t('employeesPayroll.absences.editSuccessText', {
            name: `${props.employee.firstname} ${props.employee.lastname}`,
        }),
    })

    // TODO: Edit in database
}
</script>
