<template>
    <base-modal close :show="show" size="xl" @modal-close="closeModal">
        <template #modal-header>
            <div class="flex items-center gap-1">
                <h4 class="text-lg">
                    {{ $t('employeesOverview.addEmployeeModal.title') }}
                </h4>
            </div>
        </template>

        <hr class="-mx-6" />

        <div class="pt-12">
            <employees-detail-personal-data-form :disabled="loading" />
        </div>
        <!-- Search compensations -->

        <!-- Footer -->
        <template #modal-footer>
            <div class="mt-3 flex justify-end gap-3">
                <!-- Cancel button -->
                <base-button outline variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>

                <!-- Add button -->
                <base-button
                    variant="primary"
                    @click="addEmployee"
                    :loading="loading"
                >
                    {{ $t('employeesOverview.addEmployeeModal.footer.btnAdd') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import jsonToFormData from 'json-form-data'

const emit = defineEmits(['modal-close', 'refresh-employees'])
const { t } = useI18n()

const initialValues = {
    salutation_id: null,
    lastname: '',
    firstname: '',
    hr_employee_type_id: null,
    street: null,
    postcode: null,
    city: null,
    country_id: null,
    date_of_birth: null,
    gender: 'male',
}

const { setErrors, handleSubmit } = useForm({
    initialValues,
})
const { successNotify } = useNotification()

const { loading, execute } = useApiFactory('employees').useApiCreate()

const emitter = useEmitter()

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
})

const addEmployee = handleSubmit(async (values) => {
    try {
        emitter.emit('set-loading', true)

        await execute(jsonToFormData(values), {
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'POST',
        })

        successNotify({
            title: t('general.success'),
            text: t('employeesOverview.create.addSuccess'),
        })

        closeModal()
        emit('refresh-employees')
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
    }
})
const closeModal = () => {
    emit('modal-close')
}
</script>
