<template>
    <base-section :title="$t('employeesOverview.create.contractData.headline')">
        <template #right>
            <form-control-group>
                <form-control-group-row>
                    <!-- Employed as -->
                    <div class="w-2/5">
                        <form-single-select
                            v-model="employedAs"
                            :label="
                                $t(
                                    'employeesOverview.create.contractData.employedAs.label'
                                )
                            "
                            :options="employedAsOptions"
                            :disabled="disabled || loadingEmployeeTypes"
                            :error-message="employedAsError"
                        />
                    </div>
                </form-control-group-row>

                <div class="border-t"></div>

                <form-control-group-row class="items-end">
                    <!-- Weekly working time -->
                    <div class="flex w-full items-end">
                        <form-number-input
                            class="[&_input]:rounded-r-none"
                            v-model="weeklyWorkingHours"
                            :error-message="weeklyWorkingHoursError"
                            :precision="1"
                            :label="
                                $t(
                                    'employeesOverview.create.contractData.weeklyWorkingTime.label'
                                )
                            "
                            :disabled="disabled"
                        />
                        <div
                            class="flex items-center rounded-r-lg border border-l-0 border-gray-300 px-4 py-3 text-sm"
                        >
                            {{
                                $t(
                                    'employeesOverview.create.contractData.weeklyWorkingTime.suffix'
                                )
                            }}
                        </div>
                    </div>

                    <!-- Does the employee works on holidays? -->
                    <form-toggle
                        class="w-full"
                        v-model="employeeWorkOnHolidays"
                        :label="
                            $t(
                                'employeesOverview.create.contractData.employeeWorkOnHolidays.label'
                            )
                        "
                        size="sm"
                        :disabled="disabled"
                        :error-message="employeeWorkOnHolidaysError"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <span class="text-sm font-medium text-gray-700">
                        {{
                            $t(
                                'employeesOverview.create.contractData.workingDays.label'
                            )
                        }}
                    </span>
                    <span class="text-sm text-gray-500">{{
                        displayWorkingDay
                    }}</span>
                    <base-button
                        variant="link-color"
                        icon="line-icons:general:edit-01"
                        @click="onShowWorkingTimeModal"
                        :disabled="!weeklyWorkingHours"
                    >
                        {{ $t('general.change') }}
                    </base-button>
                </form-control-group-row>
            </form-control-group>
        </template>
    </base-section>

    <employees-overview-create-working-time-modal
        v-if="!!Number(weeklyWorkingHours)"
        :show="workingTimeModal && !!Number(weeklyWorkingHours)"
        :hours="Number(weeklyWorkingHours)"
        @modal-close="onCloseWorkingTimeModal"
        @modal-save="(value) => onSaveWorkingTimeModal(value)"
    />
</template>

<script setup>
const workingTimeModal = ref(false)
const { t } = useI18n()

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { value: employedAs, errorMessage: employedAsError } = useField(
    'contract.contract_type_id'
)

const { value: weeklyWorkingHours, errorMessage: weeklyWorkingHoursError } =
    useField('work_information.weekly_working_hours')
const { value: monday } = useField(
    'work_information.weekly_working_times.monday'
)
const { value: tuesday } = useField(
    'work_information.weekly_working_times.tuesday'
)
const { value: wednesday } = useField(
    'work_information.weekly_working_times.wednesday'
)
const { value: thursday } = useField(
    'work_information.weekly_working_times.thursday'
)
const { value: friday } = useField(
    'work_information.weekly_working_times.friday'
)
const { value: saturday } = useField(
    'work_information.weekly_working_times.saturday'
)
const { value: sunday } = useField(
    'work_information.weekly_working_times.sunday'
)

const {
    value: employeeWorkOnHolidays,
    errorMessage: employeeWorkOnHolidaysError,
} = useField('work_information.is_working_holiday')

const { execute: executeGetEmployeeTypes, loading: loadingEmployeeTypes } =
    useApi('/api/contract-types', 'GET')

const employedAsOptions = ref([])

onMounted(() => {
    executeGetEmployeeTypes().then((response) => {
        employedAsOptions.value = response.data.map((type) => ({
            value: type.id,
            label: type.name,
        }))
    })
})

const onShowWorkingTimeModal = () => {
    workingTimeModal.value = true
}

const onCloseWorkingTimeModal = () => {
    workingTimeModal.value = false
}

const onSaveWorkingTimeModal = (value) => {
    weeklyWorkingHours.value = value
    workingTimeModal.value = false
}

const displayWorkingDay = computed(() => {
    const days = [
        t('employeesOverview.workingHoursModal.monday.short'),
        t('employeesOverview.workingHoursModal.tuesday.short'),
        t('employeesOverview.workingHoursModal.wednesday.short'),
        t('employeesOverview.workingHoursModal.thursday.short'),
        t('employeesOverview.workingHoursModal.friday.short'),
        t('employeesOverview.workingHoursModal.saturday.short'),
        t('employeesOverview.workingHoursModal.sunday.short'),
    ]
    const hours = [
        monday.value,
        tuesday.value,
        wednesday.value,
        thursday.value,
        friday.value,
        saturday.value,
        sunday.value,
    ]

    const daysWorked = []

    for (let i = 0; i < 7; i++) {
        if (hours[i] > 0 && !['Sa', 'Su'].includes(days[i])) {
            daysWorked.push(days[i])
        }
    }
    const result = []
    if (daysWorked.length === 0) {
        result.push('No days worked')
    } else if (daysWorked.length === 5) {
        result.push('Mo - Fr')
    } else {
        let consecutiveDays = []
        for (let i = 0; i < daysWorked.length; i++) {
            if (
                i === 0 ||
                days.indexOf(daysWorked[i]) -
                    days.indexOf(daysWorked[i - 1]) ===
                    1
            ) {
                consecutiveDays.push(daysWorked[i])
            } else {
                if (consecutiveDays.length >= 3) {
                    result.push(
                        `${consecutiveDays[0]} - ${
                            consecutiveDays[consecutiveDays.length - 1]
                        }`
                    )
                } else {
                    result.push(...consecutiveDays)
                }

                consecutiveDays = [daysWorked[i]]
            }
        }

        if (consecutiveDays.length >= 3) {
            result.push(
                `${consecutiveDays[0]} - ${
                    consecutiveDays[consecutiveDays.length - 1]
                }`
            )
        } else {
            result.push(...consecutiveDays)
        }
    }

    if (saturday.value > 0) result.push('Sa')
    if (sunday.value > 0) result.push('Su')

    return result.join(', ')
})
</script>
