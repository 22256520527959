<template>
    <base-modal
        close
        :show="show"
        size="sm"
        @modal-close="closeModal"
        class="overflow-visible"
    >
        <template #modal-header>
            <div class="gap-1">
                <h4 class="text-lg font-semibold text-gray-900">
                    {{
                        $t(
                            'employeesOverview.detail.form.employeeInformation.ghsInsuranceCategory'
                        )
                    }}
                </h4>
            </div>
        </template>
        <employees-detail-information-social-insurance-category-select
            :category="category"
            @change="onChangeCategory"
        />

        <template #modal-footer>
            <div class="mt-3 flex justify-end gap-3">
                <!-- Cancel button -->
                <base-button outline variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>

                <!-- Add button -->
                <base-button variant="primary" @click="onConfirm">
                    {{ $t('general.confirm') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const newCategory = ref('')
const emit = defineEmits(['modal-close', 'change'])
const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    category: {
        type: String,
        default: '',
    },
})
const closeModal = () => {
    emit('modal-close')
}

const onChangeCategory = (category) => {
    newCategory.value = category
}

const onConfirm = () => {
    if (newCategory.value !== '' && newCategory.value !== props.category) {
        emit('change', newCategory.value)
    }

    emit('modal-close')
}
</script>
