<template>
    <!-- Search -->
    <form-text-input
        icon-name="line-icons:general:search-lg"
        :placeholder="
            $t(
                'employeesPayroll.compensations.addCompensationModal.searchPlaceholder'
            )
        "
        :model-value="searchValue"
    >
        {{ $t('general.search') }}
    </form-text-input>

    <div class="-mx-6 my-8 border-t"></div>

    <h4 class="mb-5 text-lg">
        {{
            $t(
                'employeesPayroll.compensations.addCompensationModal.allCompensations'
            )
        }}
    </h4>

    <!-- Compensations slection -->
    <ul class="max-h-64 overflow-auto">
        <li
            v-for="compensation in componsations"
            :key="compensation.id"
            class="cursor-pointer border-b p-2.5 first:bg-success-50 hover:bg-gray-50 first:hover:bg-success-50"
            @click="onSelectAbsence(compensation.modal)"
        >
            {{ compensation.label }}
        </li>
    </ul>
</template>

<script setup>
const { t } = useI18n()

const emit = defineEmits(['compensation-select'])

const searchValue = ref('')

const onSelectAbsence = (value) => {
    emit('compensation-select', value)
}

const componsations = ref([
    {
        id: 1,
        label: t(
            'employeesPayroll.compensations.addCompensationModal.bonusPremium.optionLabel'
        ),
        modal: {
            name: 'bonus-premium',
            title: t(
                'employeesPayroll.compensations.addCompensationModal.bonusPremium.title'
            ),
        },
    },
    {
        id: 2,
        label: t(
            'employeesPayroll.compensations.addCompensationModal.downAdvance.optionLabel'
        ),
        modal: {
            name: 'down-advance',
            title: t(
                'employeesPayroll.compensations.addCompensationModal.downAdvance.title'
            ),
        },
    },
    {
        id: 3,
        label: t(
            'employeesPayroll.compensations.addCompensationModal.pensionPlan.optionLabel'
        ),
        modal: {
            name: 'pension-plan',
            title: t(
                'employeesPayroll.compensations.addCompensationModal.pensionPlan.title'
            ),
        },
    },
    {
        id: 4,
        label: t(
            'employeesPayroll.compensations.addCompensationModal.overtimePayment.optionLabel'
        ),
        modal: {
            name: 'overtime-payment',
            title: t(
                'employeesPayroll.compensations.addCompensationModal.overtimePayment.title'
            ),
        },
    },
    {
        id: 5,
        label: t(
            'employeesPayroll.compensations.addCompensationModal.holidaySupplement125.optionLabel'
        ),
        modal: {
            name: 'holiday-125',
            title: t(
                'employeesPayroll.compensations.addCompensationModal.holidaySupplement125.title'
            ),
        },
    },
    {
        id: 6,
        label: t(
            'employeesPayroll.compensations.addCompensationModal.holidaySupplement150.optionLabel'
        ),
        modal: {
            name: 'holiday-150',
            title: t(
                'employeesPayroll.compensations.addCompensationModal.holidaySupplement150.title'
            ),
        },
    },
])
</script>
