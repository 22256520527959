import { isActive } from '@tenant/core/feature'

const navigation = [
    ...(isActive('FEATURE_FLAG_1630')
        ? [
              {
                  id: 'sas-payroll',
                  path: 'employees',
                  label: 'sidebar.employees.payrollLabel',
                  icon: 'line-icons:users:users-03',
                  position: 80,
              },
              {
                  id: 'sas-employees-overview',
                  path: 'employees.overview',
                  label: 'sidebar.employees.label',
                  parent: 'sas-payroll',
                  position: 10,
              },
              {
                  id: 'sas-employees-leave',
                  path: 'employees.leave',
                  label: 'sidebar.employees.leave',
                  parent: 'sas-payroll',
                  position: 20,
              },
              {
                  id: 'sas-employees-pay-employees',
                  path: 'employees.pay-employees',
                  label: 'sidebar.employees.payEmployees',
                  parent: 'sas-payroll',
                  position: 30,
              },
              {
                  id: 'sas-employees-taxes-and-filings',
                  path: 'employees.taxes-and-filings',
                  label: 'sidebar.employees.taxesAndFilings',
                  parent: 'sas-payroll',
                  position: 40,
              },
              ...(isActive('FEATURE_FLAG_1078')
                  ? [
                        {
                            id: 'sas-employees-payroll',
                            path: 'employees.payroll',
                            label: 'sidebar.employees.payrollLabel',
                            parent: 'sas-employees',
                            position: 50,
                        },
                    ]
                  : []),
          ]
        : []),
]

export default navigation
