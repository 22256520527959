<template>
    <form-control-group>
        <form-control-group-row>
            <!-- Salutation -->
            <div class="w-2/5">
                <form-single-select
                    v-model="salutation_id"
                    :label="
                        $t(
                            'employeesOverview.create.personalData.salutation.label'
                        )
                    "
                    :error-message="salutationError"
                    :options="userSalutations"
                    :disabled="disabled || loadingSalutations"
                />
            </div>

            <!-- Name -->
            <form-text-input
                v-model="firstname"
                :error-message="firstNameError"
                :label="
                    $t('employeesOverview.create.personalData.firstName.label')
                "
                :disabled="disabled"
            />

            <!-- Last name -->
            <form-text-input
                v-model="lastname"
                :error-message="lastNameError"
                :label="
                    $t('employeesOverview.create.personalData.lastName.label')
                "
                :disabled="disabled"
            />
        </form-control-group-row>

        <div class="grid grid-cols-4 gap-x-3">
            <div class="col-span-3">
                <form-date-picker
                    v-model="dateOfBirth"
                    single
                    :error-message="dateOfBirthError"
                    :label="
                        $t(
                            'employeesOverview.create.personalData.dateOfBirth.label'
                        )
                    "
                    :disabled="disabled"
                    :hint-text="getYearOlds"
                >
                </form-date-picker>
            </div>

            <div class="flex flex-1 flex-col">
                <form-control-label
                    :label="
                        $t('employeesOverview.create.personalData.gender.label')
                    "
                />

                <div
                    class="border-1 flex flex-row items-center justify-between gap-2.5 rounded-lg border border-gray-300 px-4 py-3 pl-3.5"
                >
                    <form-radio
                        :text="
                            $t(
                                'employeesOverview.create.personalData.gender.male'
                            )
                        "
                        value="male"
                        v-model="gender"
                    />
                    <form-radio
                        :text="
                            $t(
                                'employeesOverview.create.personalData.gender.female'
                            )
                        "
                        value="female"
                        v-model="gender"
                    />
                </div>
            </div>
        </div>

        <form-control-group-row>
            <!-- Address -->
            <form-text-input
                v-model="street"
                :error-message="streetError"
                :label="
                    $t('employeesOverview.create.personalData.address.label')
                "
                :disabled="disabled"
            />
        </form-control-group-row>

        <form-control-group-row>
            <!-- Postcode -->
            <form-text-input
                v-model="postcode"
                :error-message="postcodeError"
                :label="
                    $t('employeesOverview.create.personalData.postcode.label')
                "
                :disabled="disabled"
            />
            <!-- City -->
            <form-text-input
                v-model="city"
                :error-message="cityError"
                :label="$t('employeesOverview.create.personalData.city.label')"
                :disabled="disabled"
            />
        </form-control-group-row>

        <form-control-group-row>
            <!-- Country -->
            <form-text-input
                v-model="country"
                :label="
                    $t('employeesOverview.create.personalData.country.label')
                "
                :tooltip="
                    $t('employeesOverview.create.personalData.country.tooltip')
                "
                disabled="disabled"
            />
        </form-control-group-row>

        <slot name="content"></slot>
    </form-control-group>
</template>

<script setup>
defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { t } = useI18n()

const { value: salutation_id, errorMessage: salutationError } =
    useField('salutation_id')
const { value: firstname, errorMessage: firstNameError } = useField('firstname')
const { value: lastname, errorMessage: lastNameError } = useField('lastname')
const { value: dateOfBirth, errorMessage: dateOfBirthError } =
    useField('date_of_birth')
const { value: gender } = useField('gender')
const { value: street, errorMessage: streetError } = useField('street')
const { value: postcode, errorMessage: postcodeError } = useField('postcode')
const { value: city, errorMessage: cityError } = useField('city')

const { execute: executeGetSalutations, loading: loadingSalutations } = useApi(
    '/api/salutations',
    'GET'
)

const userSalutations = ref([])
const country = ref('Cyprus')

onMounted(() => {
    executeGetSalutations().then((response) => {
        userSalutations.value = response.map((salutation) => ({
            value: salutation.id,
            label: salutation.name,
        }))
    })
})

const getYearOlds = computed(() => {
    if (dateOfBirth.value) {
        const date = new Date(dateOfBirth.value)
        const ageDifMs = Date.now() - date.getTime()
        const ageDate = new Date(ageDifMs)

        const res = Math.abs(ageDate.getUTCFullYear() - 1970)

        return `${res} ${t(
            'employeesOverview.create.personalData.dateOfBirth.yearsOld'
        )}`
    }

    return ''
})
</script>
