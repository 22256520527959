<template>
    <div
        class="flex items-center justify-between border-b border-gray-200 px-6 py-4"
    >
        <h5 class="text-lg font-medium leading-9 text-gray-900">
            {{ title }}
        </h5>
    </div>

    <div
        class="flex flex-col gap-2 overflow-auto px-6 py-5 text-md font-medium text-gray-700"
    >
        <div
            class="flex cursor-pointer items-center gap-3 rounded-md px-3 py-2"
            :class="{
                'bg-primary-600 text-white': isRoute(
                    'employees.overview.edit.detail'
                ),
            }"
            @click="onRedirectRoute('employees.overview.edit.detail')"
        >
            {{ $t('employeesOverview.detail.sidebar.personalDetails') }}
        </div>

        <div
            class="flex cursor-pointer items-center gap-3 rounded-md px-3 py-2"
            :class="{
                'bg-primary-600 text-white': isRoute(
                    'employees.overview.edit.information'
                ),
            }"
            @click="onRedirectRoute('employees.overview.edit.information')"
        >
            {{ $t('employeesOverview.detail.sidebar.employeeInformation') }}
        </div>

        <div
            class="flex cursor-pointer items-center gap-3 rounded-md px-3 py-2"
            :class="{
                'bg-primary-600 text-white': isRoute(
                    'employees.overview.edit.taxes'
                ),
            }"
            @click="onRedirectRoute('employees.overview.edit.taxes')"
        >
            {{ $t('employeesOverview.detail.sidebar.taxes') }}
        </div>
    </div>
</template>
<script setup>
defineProps({
    title: {
        type: String,
        required: true,
    },
})

const router = useRouter()
const route = useRoute()

const onRedirectRoute = (routeName) => {
    router.push({
        name: routeName,
    })
}

const isRoute = (routeName) => {
    return route.matched.some((i) => i.name === routeName)
}
</script>
