export default {
    employeesOverview: {
        detail: {
            sidebar: {
                personalDetails: 'Personal Details',
                employeeInformation: 'Employee Information',
                taxes: 'Taxes',
            },
            form: {
                employeeInformation: {
                    title: 'Details',
                    number: 'Employee number',
                    startDate: 'Employment start date',
                    holidayGroup: 'Holiday Group',
                    socialInsurance: 'Social Insurance',
                    socialInsuranceNumber: 'Social Insurance Number',
                    ghsInsuranceCategory: 'GHS Insurance Category',
                    ghsChangeCategory: 'Change category',
                    ghs: {
                        a: {
                            label: 'GHS Category A',
                            description:
                                'All employees, apart from those eligible for another category',
                        },
                        f: {
                            label: 'GHS Category F',
                            description:
                                'All eligbile Freeport employees, apart from thise in another Freeport category',
                        },
                        j: {
                            label: 'GHS Category J',
                            description: 'Employees who can defer GHS',
                        },
                        l: {
                            label: 'GHS Category L',
                            description: 'Freeport + can defer GHS',
                        },
                    },
                    salaryAndWage: 'Salary and Wages',
                    payCalendar: {
                        title: 'Pay Calendar',
                        values: {
                            monthly: 'Monthly',
                            biweekly: 'Biweekly',
                            weekly: 'Weekly',
                        },
                        description: 'Frequency of which your employee is paid',
                    },
                    regularEarnings: {
                        title: 'Regular Earnings',
                        values: {
                            annual: {
                                label: 'Annual Salary',
                            },
                            hourly: {
                                label: 'Hourly',
                            },
                        },
                        addNewDescription:
                            'Add the regular earnings of this employee and when they work to calculate their basic rate',
                        modal: {
                            title: 'Change regular earnings',
                            description:
                                "Calculate the regular earnings on the Monthly pay calendar's payslip. Learn more about adding.",
                            annualSalary: 'Annual salary',
                            hourly: 'Hourly',
                            hourlyRate: 'Hourly rate',
                            grossAnnualAmount: 'Gross annual amount',
                            basicRatePerHour: 'Basic rate per hour',
                            contractedAmount: 'Contracted amount',
                            calculatedUseWorkPattern:
                                'Calculated using work pattern',
                            workPatternTemplate: 'Working pattern template',
                            workPatternCustom: 'Custom',
                            workPatternDescription:
                                'Use decimals, colons, or hours and minutes to define the contracted time John works',
                            earningPayItem: 'Earnings pay item',
                            startDate: 'Start date',
                            earningPayItemOptions: {
                                regular_hours: 'Regular hours',
                            },
                        },
                        workingPattern: {
                            custom: 'Custom',
                            full_time: 'Mon-Fri',
                        },
                        box: {
                            effective: 'Effective {value}',
                            workingPattern: 'Working pattern {value}',
                            averageHoursPerWeek:
                                'Average hours per week {value}',
                            basicRatePerHour: 'Basic rate per hour {value}',
                        },
                    },
                    regularEarningsChange: 'Change regular earnings',
                },
                taxes: {
                    empty: {
                        title: 'You need to complete the employment information for this employee before configuring taxes',
                        btn: 'Setup employment information',
                    },
                    setup: {
                        title: 'Setup information',
                        description:
                            '{user}’s setup information will be reported to HMRC on their first pay run',
                    },
                    employeeType: {
                        title: 'Employee type',
                        values: {
                            new: {
                                title: 'New employee',
                                description:
                                    'Setting up new start with this organisation',
                            },
                            exist: {
                                title: 'Existing employee',
                                description:
                                    'Migrating paid employee from previous payroll software',
                            },
                        },
                    },
                    employeeStatement: {
                        title: 'Employee statement',
                        placeholder: 'Please select',
                    },
                    director: {
                        title: 'Directorship (optional)',
                        description: 'Set {user} up as a company director',
                        isDirector: 'Employee is a director',
                    },
                },
            },
        },
        addEmployeeModal: {
            title: 'Add an employee',
            footer: {
                btnAdd: 'Add',
            },
        },
        list: {
            headlineBadge: 'No employees | 1 employee | {count} employees',
            title: 'Employees',
            emptyText: 'No employees found',
            emptySubText: 'Add your first employee',
            headline: 'Employees',
            description:
                'An overview of each employee from every department in the organization',
            addEmployee: 'Add employee',
            filter: {
                viewAll: 'View all',
                newlyHired: 'Newly Hired',
                absentToday: 'Absent Today',
                archived: 'Archived',
            },
            employeePerson: 'Employee & Email',
            phoneNumber: 'Phone number',
            department: 'Department',
            tags: 'Tags',
            deleteSuccess: 'Employee successfully deleted!',
            deleteConfirmation: 'Enter the email to confirm',
            vacation_taken: {
                label: 'Vacation taken',
                content: '{taken} of {total} days',
            },
            payment: {
                label: 'Payment',
            },
        },
        create: {
            title: 'Create an employee',
            personalData: {
                headline: 'Personal data',
                phone1: {
                    label: 'Primary phone number (optional)',
                },
                phone2: {
                    label: 'Secondary phone number (optional)',
                    add: 'Add secondary phone number',
                },
                salutation: {
                    label: 'Salutation *',
                },
                firstName: {
                    label: 'Firstname *',
                },
                lastName: {
                    label: 'Lastname *',
                },
                dateOfBirth: {
                    label: 'Date of Birth',
                    yearsOld: 'Years old',
                },
                nationality: {
                    label: 'Nationality',
                },
                address: {
                    label: 'Address',
                },
                postcode: {
                    label: 'Postcode',
                },
                city: {
                    label: 'City',
                },
                country: {
                    label: 'Country',
                    tooltip: 'Country tooltip',
                },
                gender: {
                    label: 'Gender',
                    male: 'Male',
                    female: 'Female',
                },
            },
            entry: {
                headline: 'Entry',
                dateOfEntry: {
                    label: 'Entry',
                },
                dateOfExit: {
                    label: 'Exit',
                },
                alert: {
                    headline: 'Attention',
                    description:
                        'The employee started work later than the current payroll month of {month} {year}. This means he will not be included in the next payroll.',
                },
            },
            contractData: {
                headline: 'Contract data',
                employedAs: {
                    label: 'Employed as *',
                    optionEmployee: 'Employee',
                    optionMinijobber: 'Minijobber',
                    optionDirector: 'Director',
                    optionStudent: 'Student',
                    optionPensioner: 'Pensioner',
                },
                weeklyWorkingTime: {
                    label: 'Weekly working time',
                    suffix: 'Hours',
                },
                employeeWorkOnHolidays: {
                    label: 'Does the employee works on holidays?',
                },
                workingDays: {
                    label: 'Working days',
                },
            },
            vacation: {
                headline: 'Manage vacation days',
                button: 'Manage vacation days',
            },
            compensation: {
                invalidIban: 'Invalid IBAN',
                headline: 'Compensation',
                payroll: {
                    label: 'Payroll',
                    optionSalary: 'Salary',
                    optionHourlyRate: 'Hourly rate',
                },
                amount: {
                    label: 'Amount',
                    suffix: 'EUR',
                },
                thirteenSalary: {
                    label: 'Thirteen salary?',
                },
                tipAlert: {
                    headline: 'Tip',
                    description:
                        'Other wage types such as retirement provision, company car or holiday pay can be recorded in the payroll after the employee has been created.',
                },
                payrollOptions: {
                    bankTransfer: 'Bank transfer',
                    cash: 'Cash',
                },
                iban: {
                    label: 'IBAN',
                },
                bank: {
                    label: 'Bank',
                },
                cashAlert: {
                    description:
                        'Cash payment is accompanied by an extended documentation requirement. This is time-consuming and can lead to errors. We therefore recommend that wages are always paid by bank transfer.',
                },
            },
            socialSecurity: {
                headline: 'Social Secuirty',
                socialInsuranceNumber: {
                    label: 'Social insurance number',
                },
                yellowSlipId: {
                    label: 'Yellow Slip ID',
                },
                yellowSlipDocumentUpload: {
                    label: 'Yellow slip document upload',
                    description: 'JPG, PNG, or PDF (max. 800x400px)',
                },
            },
            addSuccess: 'The employee has been successfully added',
        },
        edit: {
            title: 'Edit an employee',
            editSuccess: 'The employee has been successfully edited',
        },
        vacationModal: {
            title: 'Record vacation entitlement',
            alert: {
                description:
                    'Based on the working days stored ({workingDays}), Cybooks has determined a minimum vacation of {days} days.',
                button: 'Take over',
            },
            regulatedAnnualLeave: {
                label: 'Contractually regulated annual leave',
                placeholder: 'min. {days} days',
                suffix: 'Days',
            },
            alreadyTaken: {
                label: 'Vacation already taken in {year}',
                suffix: 'Days',
            },
            calculatedVacationCollapse:
                'Calculated vacation entitlement for {year}',
            contractualAnnualLeave: 'Contractual annual leave ( {days} Days )',
            days: 'Days',
            actions: {
                save: 'Save and close',
            },
        },
        workingHoursModal: {
            title: 'Working time',
            monday: {
                label: 'Monday',
                short: 'Mo',
            },
            // generate for all days
            tuesday: {
                label: 'Tuesday',
                short: 'Tu',
            },
            wednesday: {
                label: 'Wednesday',
                short: 'We',
            },
            thursday: {
                label: 'Thursday',
                short: 'Th',
            },
            friday: {
                label: 'Friday',
                short: 'Fr',
            },
            saturday: {
                label: 'Saturday',
                short: 'Sa',
            },
            sunday: {
                label: 'Sunday',
                short: 'Su',
            },
            week: 'Week',
            actions: {
                save: 'Save and close',
            },
        },
    },

    employeesDepartments: {
        list: {
            headline: 'Departments',
            headlineBadge:
                'No departments | 1 department | {count} departments',
            description: 'A division of your organization',
            addDepartment: 'Add department',
            filter: {
                viewAll: 'View all',
                archived: 'Archived',
            },
            name: 'Name',
            manager: 'Manager',
            parentDepartment: 'Parent Department',
            numberOfEmployees: 'Number of Employees',
            deleteSuccess: 'Department successfully deleted!',
            emptyText: 'No departments found',
            emptySubText: 'Add your first department',
            deleteConfirmation: 'Enter the name to confirm',
        },
        create: {
            title: 'Create a department',
            description: 'A division of your organization',
            general: {
                headline: 'General information',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                departmentName: {
                    label: 'Department Name *',
                },
                parentDepartment: {
                    label: 'Parent Department',
                },
                manager: {
                    label: 'Manager',
                },
            },
            addSuccess: 'Department successfully created!',
        },
        filter: {
            type: 'Type *',
            relationshipType: {
                manager: 'Manager',
                parent: 'Parent Department',
            },
        },
        edit: {
            title: 'Edit an department',
            editSuccess: 'Department successfully updated!',
        },
    },

    employeesJobPositions: {
        list: {
            headline: 'Job Positions',
            headlineBadge:
                'No job positions | One job position | {count} job positions',
            description: 'A division of your organization',
            addJobPosition: 'Add job position',
            filter: {
                viewAll: 'View all',
                archived: 'Archived',
            },
            table: {
                name: 'Name',
                department: 'Department',
                currentNumberOfEmployees: 'Current number of Employees',
                expectedNewEmployees: 'Expected New Employees',
                published: 'Published',
                interviewers: 'Interviewers',
                recruiter: 'Recruiter',
            },
            emptyText: '',
            emptySubText: '',
            deleteSuccess: 'Job position successfully deleted!',
            deleteConfirmation: 'Enter the name to confirm',
        },
        create: {
            title: 'Create a job position',
            description: 'A division of your organization',
            recruitmentSection: {
                title: 'Recruitment',
                jobPositionName: {
                    label: 'Job Position Name *',
                },
                department: {
                    label: 'Department *',
                },
                interviewers: {
                    label: 'Interviewers',
                },
                recruiter: {
                    label: 'Recruiter',
                },
                expectedNewEmployees: {
                    label: 'Expected New Employees',
                },
                published: {
                    label: 'Published',
                },
            },
            addSuccess: 'Job position successfully created!',
            jobDescriptionSection: {
                title: 'Job Description',
            },
        },
        edit: {
            title: 'Edit a job positions',
            editSuccess: 'Job position successfully updated!',
        },
    },

    employeesContractTypes: {
        list: {
            headline: 'Contract Types',
            headlineBadge:
                'No contract types | 1 contract type | {count} contract types',
            addContractType: 'Add contract type',
            table: {
                name: 'Name',
            },
            deleteSuccess: 'Contract type successfully deleted!',
            deleteConfirmation:
                'Enter the name of the contract type to confirm',
        },
        create: {
            title: 'Create a contract type',
            name: {
                label: 'Name *',
            },
            createSuccess: 'Contract type successfully created!',
        },
        edit: {
            title: 'Edit an contract type',
            editSuccess: 'Contract type successfully updated!',
        },
    },

    employeesPayroll: {
        title: 'Payroll {month} {year}',
        steps: {
            absencesLabel: 'Absences',
            hoursLabel: 'Hours and charges',
            finalizeLabel: 'Check and finalize',
        },

        absences: {
            addSuccessTitle: 'Absence added.',
            addSuccessText: "You've successfully added an absence for {name}",
            editSuccessTitle: 'Absence updated.',
            editSuccessText:
                'You’ve successfully updated an absence for {name}.',
            deletedSuccessTitle: 'Absence deleted.',
            deletedSuccessText:
                'You’ve successfully deleted an absence for {name}.',

            confirmDelete: {
                title: 'Do you really want to delete this absence?',
                description: '{type} of {name}',
                downDays: '{days} down days',
            },

            table: {
                badge: 'Vacation taken in {year}: {days} days',
                addAbsences: 'Add Absences',
                absencesLabel: 'Absences',
                periodLabel: 'Period',
                downDaysLabel: 'Down days',
                empty: 'No absences in {year}',
                downDaysCellText: '{days} working days',
                emptyText: '',
                emptySubText: '',
            },

            absenceModal: {
                searchPlaceholder: 'Search absences',
                allAbsences: 'All absences',
                forLabel: 'For',
                noteLabel: 'Note:',

                addTitle: 'Add',
                add: 'Add',

                editTitle: 'Edit',
                edit: 'Edit',

                general: {
                    optionLabel: 'General',
                    type: {
                        label: 'Type',
                    },
                    reason: {
                        label: 'Reason',
                    },
                    from: {
                        label: 'From',
                    },
                    until: {
                        label: 'Until',
                    },
                    workingFirstDay:
                        'Was still working on the first day of illness',
                    selfReported: 'John Doe duly reported sick',
                    diagnosedByDoctor:
                        'The disease was diagnosed by a doctor or hospital',
                },

                stayAbroad: {
                    optionLabel: 'Stay abroad',
                    note: 'Period of employment abroad that is not covered by the Cypriot statutory accident insurance.',
                    from: {
                        label: 'From',
                    },
                    until: {
                        label: 'Until',
                    },
                },

                dualStudy: {
                    optionLabel: 'Dual study',
                    note: 'Use this absence for the study phases in dual studies.',
                    from: {
                        label: 'From',
                    },
                    until: {
                        label: 'Until',
                    },
                },

                parentalLeave: {
                    optionLabel: 'Parental leave',
                    from: {
                        label: 'From',
                    },
                    until: {
                        label: 'Until',
                    },
                },

                unpaidLeave: {
                    optionLabel: 'Unpaid leave',
                    from: {
                        label: 'From',
                    },
                    until: {
                        label: 'Until',
                    },
                },

                unexcused: {
                    optionLabel: 'Unexcused absence',
                    from: {
                        label: 'From',
                    },
                    until: {
                        label: 'Until',
                    },
                },

                unlawfulStrike: {
                    optionLabel: 'Unlawful strike',
                    from: {
                        label: 'From',
                    },
                    until: {
                        label: 'Until',
                    },
                },

                irrevocableExemption: {
                    optionLabel: 'Irrevocable exemption until resignation',
                    note: 'Use this absence if the employee is on leave of absence after a termination until the actual resignation with continued pay.',
                    from: {
                        label: 'From',
                    },
                    until: {
                        label: 'Until',
                    },
                },

                holiday: {
                    optionLabel: 'Holiday',
                    availableDaysLabel: 'Available vacation days:',
                    from: {
                        label: 'From',
                    },
                    until: {
                        label: 'Until',
                    },
                    typeFirstDay: {
                        label: 'Vacation type 1. day',
                    },
                    typeLastDay: {
                        label: 'Vacation type last day',
                    },
                },
            },
        },

        compensations: {
            table: {
                badge: 'Vacation taken in {year}: {days} days',
                addCompensation: 'Add Compensation',
                compensationLabel: 'Compensation',
                hoursLabel: 'Number',
                amountLabel: 'Amount',
                finalAmountLabel: 'Final amount',
                emptyText: 'No absences in {year}',
                emptySubText: '',
                hoursSuffix: 'hours',
            },

            addCompensationModal: {
                searchPlaceholder: 'Search compensations',
                allCompensations: 'All compensations',
                add: 'Add',
                forLabel: 'For',

                general: {
                    title: 'Add compensation',
                },

                bonusPremium: {
                    optionLabel: 'Bonus / Premium',
                    title: 'Add Bonus / Premium',

                    quantity: {
                        label: 'Quantity',
                    },
                    amount: {
                        label: 'Amount',
                    },
                    paymentInterval: {
                        label: 'Payment interval',
                    },
                    totalLabel: 'Bonus / Reward',
                },

                downAdvance: {
                    optionLabel: 'Down payment / advance payment',
                    title: 'Add Down payment / advance payment',

                    quantity: {
                        label: 'Quantity',
                    },
                    amount: {
                        label: 'Amount',
                    },
                    paymentInterval: {
                        label: 'Payment interval',
                    },
                    totalLabel: 'Down payment / advance payment',
                },

                pensionPlan: {
                    optionLabel: 'Pension plan Employer allowance',
                    title: 'Add Pension plan Employer allowance',

                    quantity: {
                        label: 'Quantity',
                    },
                    amount: {
                        label: 'Amount',
                    },
                    paymentInterval: {
                        label: 'Payment interval',
                    },
                    alert: {
                        title: 'Important information',
                        description:
                            'With the selection of the payments "Pension plan Employer allowance" and "Pension plan deferred compensation", Cybooks automatically makes a net deduction "Transaction Pension plan deferred compensatio" on the payroll.',
                    },
                    totalLabel: 'Pension plan Employer allowance',
                },

                overtimePayment: {
                    optionLabel: 'Overtime payment',
                    title: 'Add Overtime payment',

                    hours: {
                        label: 'Hours',
                    },
                    hourlyRate: {
                        label: 'Hourly rate',
                    },
                    checkboxOptions: {
                        label: 'Was overtime worked in the current month?',
                        option1: 'Yes, only this month',
                        option2: 'No, over several months.',
                    },
                    alert: {
                        title: 'Important information',
                        description:
                            'The settlement and payment of overtime worked over several months is subject to contributions as a one-time payment.',
                    },
                    totalLabel: 'Overtime payment',
                },

                holidaySupplement125: {
                    optionLabel: 'Holiday supplement 125% (tax-free)',
                    title: 'Add Holiday supplement 125% (tax-free)',
                    text: 'Tax-free payable for hours worked on public holidays (regardless of time) and on 12/31 after 2pm.',
                    percents: 'x 125 %',

                    hours: {
                        label: 'Hours',
                    },
                    hourlyRate: {
                        label: 'Hourly rate',
                    },
                    totalLabel: 'Holiday supplement 125% (tax-free)',
                },

                holidaySupplement150: {
                    optionLabel: 'Holiday supplement 150% (tax-free)',
                    title: 'Add Holiday supplement 150% (tax-free)',
                    text: 'Tax-free payable for hours worked on public holidays (regardless of time) and on 12/31 after 2pm.',
                    percents: 'x 150 %',

                    hours: {
                        label: 'Hours',
                    },
                    hourlyRate: {
                        label: 'Hourly rate',
                    },
                    totalLabel: 'Holiday supplement 150% (tax-free)',
                },
            },

            salaryModal: {
                total: 'Salary',
                forLabel: 'For',
                editBtn: 'Save and transfer to employee data',

                salary: {
                    modalTitle: 'Edit salary',
                    salaryLabel: 'Salary',
                    alert: {
                        title: 'Important information',
                        description:
                            'Changes in salary are reflected in the employee master data. The changed salary will then be used for future billing.',
                    },
                },

                hourly: {
                    modalTitle: 'Edit hourly rate',
                    hoursLabel: 'Hours',
                    rateLabel: 'Rate',
                    alert: {
                        title: 'Important information',
                        description:
                            'Changes in salary are reflected in the employee master data. The changed salary will then be used for future billing.',
                    },
                },
            },
        },
    },
}
