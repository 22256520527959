<template>
    <base-section :title="$t('employeesOverview.create.compensation.headline')">
        <template #right>
            <form-control-group>
                <form-control-group-row class="items-end">
                    <!-- Payroll -->
                    <div class="w-2/5">
                        <form-single-select
                            v-model="payroll"
                            :label="
                                $t(
                                    'employeesOverview.create.compensation.payroll.label'
                                )
                            "
                            :options="payrollOptions"
                            :error-message="payrollError"
                            :disabled="disabled"
                        />
                    </div>

                    <!-- Amount -->
                    <div class="flex w-2/5 items-end">
                        <form-number-input
                            class="[&_input]:rounded-r-none"
                            v-model="amount"
                            :error-message="amountError"
                            :label="
                                $t(
                                    'employeesOverview.create.compensation.amount.label'
                                )
                            "
                            :disabled="disabled"
                        />
                        <div
                            class="flex items-center rounded-r-lg border border-l-0 border-gray-300 px-4 py-3 text-sm"
                        >
                            {{
                                $t(
                                    'employeesOverview.create.compensation.amount.suffix'
                                )
                            }}
                        </div>
                    </div>

                    <!-- Thirteen sallary -->
                    <form-toggle
                        v-model="thirteenSalary"
                        :label="
                            $t(
                                'employeesOverview.create.compensation.thirteenSalary.label'
                            )
                        "
                        size="sm"
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <base-alert
                        :title="
                            $t(
                                'employeesOverview.create.compensation.tipAlert.headline'
                            )
                        "
                        :description="
                            $t(
                                'employeesOverview.create.compensation.tipAlert.description'
                            )
                        "
                        variant="primary"
                    />
                </form-control-group-row>

                <div class="border-t"></div>

                <form-control-group-row>
                    <!-- Payroll options -->
                    <form-radio
                        :text="
                            $t(
                                'employeesOverview.create.compensation.payrollOptions.bankTransfer'
                            )
                        "
                        name="money-transfer"
                        v-model="isUsingBankTransfer"
                        :value="true"
                        checked
                        :disabled="disabled"
                    />
                    <form-radio
                        :text="
                            $t(
                                'employeesOverview.create.compensation.payrollOptions.cash'
                            )
                        "
                        name="money-transfer"
                        v-model="isUsingBankTransfer"
                        :value="false"
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <!-- IBAN -->
                    <form-text-input
                        v-model="iban"
                        :error-message="ibanError"
                        :label="
                            $t(
                                'employeesOverview.create.compensation.iban.label'
                            )
                        "
                        :disabled="disabled"
                        @change="onChangeIban($event.target.value)"
                    />

                    <form-text-input
                        v-model="bank"
                        :error-message="bankError"
                        :label="
                            $t(
                                'employeesOverview.create.compensation.bank.label'
                            )
                        "
                        disabled
                    />
                </form-control-group-row>

                <base-alert
                    v-if="!isUsingBankTransfer"
                    :description="
                        $t(
                            'employeesOverview.create.compensation.cashAlert.description'
                        )
                    "
                    variant="primary"
                />
            </form-control-group>
        </template>
    </base-section>
</template>

<script setup>
import { useApi } from '@tenant/composables'

const { t } = useI18n()
import { ibanToBic } from 'iban-to-bic'

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { execute: executeFetchBankAccountName } = useApi(
    '/api/international-banks/{bic}/get-bank-name',
    'GET'
)

const { value: payroll, errorMessage: payrollError } = useField(
    'contract.payroll_type'
)
const { value: amount, errorMessage: amountError } = useField('contract.wage')
const { value: thirteenSalary } = useField('contract.is_thirteen_salary')
const { value: isUsingBankTransfer } = useField(
    'private_information.is_using_bank_transfer'
)
const {
    value: iban,
    errorMessage: ibanError,
    setErrors: setIbanError,
} = useField('private_information.bank_account_number')
const {
    value: bank,
    errorMessage: bankError,
    setErrors: setBankError,
    setValue: setBank,
} = useField('private_information.bank_account_name')

const payrollOptions = ref([
    {
        value: 'salary',
        label: t('employeesOverview.create.compensation.payroll.optionSalary'),
    },
    {
        value: 'hourly',
        label: t(
            'employeesOverview.create.compensation.payroll.optionHourlyRate'
        ),
    },
])

const onChangeIban = (iban) => {
    if (!iban) {
        setBank(null)
        setIbanError(null)

        return
    }

    const bic = ibanToBic(iban)
    if (!bic) {
        setBank(null)
        setIbanError(t('employeesOverview.create.compensation.invalidIban'))

        return
    }

    executeFetchBankAccountName({ params: { bic: bic } })
        .then((response) => {
            setBank(response.name)
        })
        .catch(({ errorMessage }) => {
            setBankError(errorMessage)
        })
}
</script>
