<template>
    <div class="flex justify-between">
        <span class="text-sm font-medium text-gray-700">
            {{
                $t(
                    'employeesOverview.detail.form.employeeInformation.regularEarnings.title'
                )
            }}
        </span>
    </div>
    <div
        class="border-1 flex flex-col items-start justify-between gap-y-2.5 self-stretch rounded border border-gray-300 p-6"
    >
        <span class="text-xs font-normal text-gray-600">
            {{
                $t(
                    'employeesOverview.detail.form.employeeInformation.regularEarnings.addNewDescription'
                )
            }}
        </span>

        <base-button
            :label="$t('general.add')"
            variant="primary"
            icon="line-icons:general:plus"
            size="sm"
            @click="onOpenModal"
        />
    </div>
</template>

<script setup>
const emit = defineEmits(['openModal'])

const onOpenModal = () => {
    emit('openModal')
}
</script>
