<template>
    <!-- Search -->
    <form-text-input
        icon-name="line-icons:general:search-lg"
        :placeholder="
            $t('employeesPayroll.absences.absenceModal.searchPlaceholder')
        "
        :model-value="searchValue"
    >
        {{ $t('general.search') }}
    </form-text-input>

    <div class="-mx-6 my-8 border-t"></div>

    <h4 class="mb-5 text-lg">
        {{ $t('employeesPayroll.absences.absenceModal.allAbsences') }}
    </h4>

    <!-- Absences slection -->
    <ul class="max-h-64 overflow-auto">
        <li
            v-for="absence in absences"
            :key="absence.id"
            class="cursor-pointer border-b p-2.5 first:bg-success-50 hover:bg-gray-50 first:hover:bg-success-50"
            @click="onSelectAbsence(absence)"
        >
            {{ absence.label }}
        </li>
    </ul>
</template>

<script setup>
const { t } = useI18n()

const emit = defineEmits(['absence-select'])

const searchValue = ref('')

const onSelectAbsence = (value) => {
    emit('absence-select', value)
}

const absences = ref([
    {
        id: 1,
        label: t('employeesPayroll.absences.absenceModal.general.optionLabel'),
        value: 'general',
    },
    {
        id: 2,
        label: t(
            'employeesPayroll.absences.absenceModal.stayAbroad.optionLabel'
        ),
        value: 'stay-abroad',
    },
    {
        id: 3,
        label: t(
            'employeesPayroll.absences.absenceModal.dualStudy.optionLabel'
        ),
        value: 'dual-study',
    },
    {
        id: 4,
        label: t(
            'employeesPayroll.absences.absenceModal.parentalLeave.optionLabel'
        ),
        value: 'parental-leave',
    },
    {
        id: 5,
        label: t(
            'employeesPayroll.absences.absenceModal.unpaidLeave.optionLabel'
        ),
        value: 'unpaid-leave',
    },
    {
        id: 6,
        label: t(
            'employeesPayroll.absences.absenceModal.unexcused.optionLabel'
        ),
        value: 'unexcused',
    },
    {
        id: 7,
        label: t(
            'employeesPayroll.absences.absenceModal.unlawfulStrike.optionLabel'
        ),
        value: 'unlawful-strike',
    },
    {
        id: 8,
        label: t(
            'employeesPayroll.absences.absenceModal.irrevocableExemption.optionLabel'
        ),
        value: 'irrevocable-exemption',
    },
    {
        id: 9,
        label: t('employeesPayroll.absences.absenceModal.holiday.optionLabel'),
        value: 'holiday',
    },
])
</script>
