<template>
    <form-control-group>
        <div>
            <!-- For -->
            <p class="mb-2.5 text-sm">
                {{ $t('employeesPayroll.absences.absenceModal.forLabel') }}
                {{ employeeName }}
            </p>

            <!-- Available days -->
            <p class="text-sm">
                <span class="font-semibold">
                    {{
                        $t(
                            'employeesPayroll.absences.absenceModal.holiday.availableDaysLabel'
                        )
                    }}
                </span>
                {{ availableVacationDays.toFixed(1) }}
            </p>
        </div>

        <!-- From - Until -->
        <form-control-group-row>
            <!-- From -->
            <form-date-picker
                v-model="absenceFrom"
                :label="
                    $t(
                        'employeesPayroll.absences.absenceModal.holiday.from.label'
                    )
                "
                single
                auto-apply
            />

            <!-- Until -->
            <form-date-picker
                v-model="absenceUntil"
                :label="
                    $t(
                        'employeesPayroll.absences.absenceModal.holiday.until.label'
                    )
                "
                single
                auto-apply
            />
        </form-control-group-row>

        <!-- Vacation fields -->
        <form-control-group-row>
            <!-- Vacation type 1. day -->
            <form-single-select
                label="employeesPayroll.absences.absenceModal.holiday.typeFirstDay.label"
                :model-value="absenceTypeFirstDay"
                :options="absenceTypeFirstDayOptions"
            />

            <!-- Vacation type last day -->
            <form-single-select
                label="employeesPayroll.absences.absenceModal.holiday.typeLastDay.label"
                :model-value="absenceTypeLastDay"
                :options="absenceTypeLastDayOptions"
            />
        </form-control-group-row>
    </form-control-group>
</template>

<script setup>
const { t } = useI18n()
const emit = defineEmits(['set-absence'])
const availableVacationDays = 2

const props = defineProps({
    employeeName: {
        type: String,
    },

    absence: {
        type: Object,
        default: null,
    },
})

const { value: absenceFrom } = useField('absenceFrom')
const { value: absenceUntil } = useField('absenceUntil')
const { value: absenceTypeFirstDay } = useField('absenceTypeFirstDay')
const { value: absenceTypeLastDay } = useField('absenceTypeLastDay')

onMounted(() => {
    if (props.absence) {
        absenceFrom.value = props.absence.periodStart
        absenceUntil.value = props.absence.periodEnd
    }
})

watch(
    () => [
        absenceFrom.value,
        absenceUntil.value,
        absenceTypeFirstDay.value,
        absenceTypeLastDay.value,
    ],
    () => {
        emit('set-absence', {
            type: 'holiday',
            name: t(
                'employeesPayroll.absences.absenceModal.holiday.optionLabel'
            ),
            periodStart: absenceFrom.value,
            periodEnd: absenceUntil.value,
            days: 0,
            absenceTypeFirstDay: absenceTypeFirstDay.value,
            absenceTypeLastDay: absenceTypeLastDay.value,
        })
    }
)
</script>
