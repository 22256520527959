<template>
    <base-section :title="$t('employeesOverview.create.vacation.headline')">
        <template #right>
            <form-control-hint-text
                :error-message="regulatedAnnualLeaveError || alreadyTakenError"
            />
            <base-button
                variant="default"
                outline
                @click="onShowVacationModal"
                :disabled="disabled"
            >
                {{ $t('employeesOverview.create.vacation.button') }}
            </base-button>

            <employees-overview-create-vacation-modal
                :show="vacationModal"
                @modal-close="onCloseVacationModal"
            />
        </template>
    </base-section>
</template>

<script setup>
const regulatedAnnualLeaveError = useFieldError(
    'vacation_information.annual_leave_days_in_year'
)

const alreadyTakenError = useFieldError('vacation_information.days_taken')

const vacationModal = ref(false)

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const onShowVacationModal = () => {
    vacationModal.value = true
}

const onCloseVacationModal = () => {
    vacationModal.value = false
}
</script>
