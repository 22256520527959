<template>
    <base-page-header :title="$t('employeesOverview.edit.title')" />

    <employees-overview-create-personal-data
        :disabled="loadingGet || loadingSave"
    />
    <employees-overview-create-entry :disabled="loadingGet || loadingSave" />
    <employees-overview-create-contract-data
        :disabled="loadingGet || loadingSave"
    />
    <employees-overview-create-vacation :disabled="loadingGet || loadingSave" />
    <employees-overview-create-compensation
        :disabled="loadingGet || loadingSave"
    />
    <employees-overview-create-social-security
        :disabled="loadingGet || loadingSave"
    />

    <div class="flex justify-end gap-5 pt-5">
        <base-button outline variant="default" @click="onCancel">
            {{ $t('general.cancel') }}
        </base-button>

        <base-button
            variant="primary"
            icon="line-icons:general:check"
            @click="onSave"
            :loading="loadingGet || loadingSave"
        >
            {{ $t('general.save') }}
        </base-button>
    </div>
</template>

<script setup>
import jsonToFormData from 'json-form-data'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const { setValues, setErrors, handleSubmit } = useForm()
const { successNotify } = useNotification()

const { useApiGet, useApiSave } = useApiFactory('employees')
const { loading: loadingGet, execute: executeGet } = useApiGet()
const { loading: loadingSave, execute: executeSave } = useApiSave()
const emitter = useEmitter()

useHead({
    title: t('employeesOverview.edit.title'),
})

const onCancel = () => {
    router.push({ name: 'employees.overview.list' })
}

onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        const employee = await executeGet(route.params.id)

        setValues({
            ...employee,
            private_information: {
                is_using_bank_transfer: false,
                social_insurance_no: null,
                yellow_slip_identify: null,
                bank_account_number: null,
                bank_account_name: null,
                date_of_birth: null,
                ...employee.private_information,
            },
            work_information: {
                is_working_holiday: false,
                weekly_working_times: {
                    monday: null,
                    tuesday: null,
                    wednesday: null,
                    thursday: null,
                    friday: null,
                    saturday: null,
                    sunday: null,
                    weekly_working_hours: 0,
                    ...employee.work_information.weekly_working_times,
                },
                ...employee.work_information,
            },
            vacation_information: {
                min_days: null,
                annual_leave_days_in_year: null,
                days_taken: null,
                annual_leave_days_actual: null,
                calculated_annual_leave_days_actual: null,
                ...employee.vacation_information,
            },
            contract: {
                wage: null,
                payroll_type: null,
                is_thirteen_salary: false,
                ...employee.contract,
            },
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const onSave = handleSubmit(async (values) => {
    try {
        emitter.emit('set-loading', true)

        await executeSave(jsonToFormData(values), route.params.id, {
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'POST',
        })

        await router.push({ name: 'employees.overview.list' })

        successNotify({
            title: t('general.success'),
            text: t('employeesOverview.edit.editSuccess'),
        })
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
    }
})
</script>
