<template>
    <div class="flex justify-between">
        <span class="text-sm font-medium text-gray-700">
            {{
                $t(
                    'employeesOverview.detail.form.employeeInformation.ghsInsuranceCategory'
                )
            }}
        </span>

        <span
            class="text-sm font-medium text-primary-700 hover:cursor-pointer"
            @click="onShowEdit"
        >
            {{
                $t(
                    'employeesOverview.detail.form.employeeInformation.ghsChangeCategory'
                )
            }}
        </span>
    </div>

    <div
        class="border-1 flex items-start justify-between self-stretch rounded border border-gray-300 p-6"
    >
        <div class="flex flex-col items-start gap-3">
            <span class="text-md font-medium text-gray-900">
                {{
                    $t(
                        'employeesOverview.detail.form.employeeInformation.ghs.' +
                            category +
                            '.label'
                    )
                }}
            </span>

            <span class="text-xs font-normal text-gray-600">
                {{
                    $t(
                        'employeesOverview.detail.form.employeeInformation.ghs.' +
                            category +
                            '.description'
                    )
                }}
            </span>
        </div>

        <div class="flex items-center justify-end gap-2.5">
            <base-badge
                v-if="isActive"
                variant="success"
                :label="$t('general.active')"
            />
            <base-badge
                v-else
                variant="warning"
                :label="$t('general.unsaved')"
            />

            <base-context-menu class="hover:cursor-pointer">
                <template #trigger="{ toggleMenu }">
                    <div @click="toggleMenu">
                        <base-icon
                            variant="grayLight"
                            name="line-icons:general:dots-vertical"
                        />
                    </div>
                </template>

                <base-context-menu-item @click="onShowEdit">
                    {{ $t('general.edit') }}
                </base-context-menu-item>

                <base-context-menu-item @click="onDelete">
                    {{ $t('general.delete') }}
                </base-context-menu-item>
            </base-context-menu>
        </div>
    </div>
</template>
<script setup>
const emitter = defineEmits(['onEdit', 'onDelete'])
const props = defineProps({
    category: {
        type: String,
        required: true,
    },
    isActive: {
        type: Boolean,
        default: true,
    },
})

const onShowEdit = () => {
    emitter('onEdit', props.category)
}

const onDelete = () => {
    emitter('onDelete')
}
</script>
