<template>
    <base-page-header
        :title="
            $t('employeesPayroll.title', {
                year: new Date().getFullYear(),
                month: new Date().toLocaleString('default', { month: 'long' }),
            })
        "
    />

    <!-- Steps -->
    <base-steps class="mb-6 px-10" :active="1" :steps="steps" />

    <!-- Employees -->
    <div v-for="(employee, index) in dataSource.data" :key="index" class="mb-6">
        <employees-payroll-absences-grid :employee="employee" />
    </div>

    <div class="flex justify-end">
        <base-button size="sm" @click="onContinue()">
            {{ $t('general.continue') }}
        </base-button>
    </div>
</template>

<script setup>
const { t } = useI18n()
const router = useRouter()

const { useApiSearch } = useApiFactory('employees')

const { execute: executeSearch } = useApiSearch()

const dataSource = ref([])
const steps = [
    {
        name: t('employeesPayroll.steps.absencesLabel'),
    },
    {
        name: t('employeesPayroll.steps.hoursLabel'),
    },
    {
        name: t('employeesPayroll.steps.finalizeLabel'),
    },
]

onMounted(() => {
    getEmployeesList()
})

const getEmployeesList = () => {
    executeSearch()
        .then((response) => {
            dataSource.value = response
            throw new Error('')
        })
        .catch(() => {
            dataSource.value = {
                total: 2,
                data: [
                    {
                        id: 1,
                        firstname: 'John',
                        lastname: 'Doe',
                    },
                    {
                        id: 2,
                        firstname: 'Max',
                        lastname: 'Mustermann',
                    },
                ],
            }
        })
}

const onContinue = () => {
    router.push({ name: 'employees.payroll.compensations' })
}
</script>
