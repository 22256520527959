<template>
    <base-modal close :show="show" size="lg" @modal-close="closeModal">
        <template #modal-header>
            <div class="flex items-center gap-1">
                <base-button
                    v-if="modalStep == 2"
                    @click="onReturn"
                    variant="link-gray"
                    :has-padding="false"
                >
                    <base-icon
                        name="line-icons:arrows:chevron-left"
                        variant="inherit"
                        size="lg"
                    />
                </base-button>

                <h4 class="text-lg">
                    {{
                        editedSalary.type == 'salary'
                            ? $t(
                                  'employeesPayroll.compensations.salaryModal.salary.modalTitle'
                              )
                            : $t(
                                  'employeesPayroll.compensations.salaryModal.hourly.modalTitle'
                              )
                    }}
                </h4>
            </div>
        </template>

        <!-- For -->
        <p class="mb-2.5 text-sm">
            {{ $t('employeesPayroll.compensations.salaryModal.forLabel') }}
            {{ employeeName }}
        </p>

        <!-- Hourly -->
        <template v-if="editedSalary.hours || editedSalary.amount">
            <form-control-group-row>
                <!-- Hours -->
                <div class="w-1/2">
                    <form-number-input
                        v-model="editedSalary.hours"
                        :label="
                            $t(
                                'employeesPayroll.compensations.salaryModal.hourly.hoursLabel'
                            )
                        "
                        size="sm"
                    />
                </div>

                <!-- Hourly Rate -->
                <div class="flex w-1/2 items-end">
                    <form-number-input
                        class="[&_input]:rounded-r-none"
                        v-model="editedSalary.amount"
                        :label="
                            $t(
                                'employeesPayroll.compensations.salaryModal.hourly.rateLabel'
                            )
                        "
                        size="sm"
                    />
                    <div
                        class="flex items-center rounded-r-lg border border-l-0 border-gray-300 px-4 py-2.5 text-sm"
                    >
                        {{ currency.iso }}
                    </div>
                </div>
            </form-control-group-row>

            <!-- Alert -->
            <base-alert
                class="my-2.5"
                variant="primary"
                :title="
                    $t(
                        'employeesPayroll.compensations.salaryModal.hourly.alert.title'
                    )
                "
                :description="
                    $t(
                        'employeesPayroll.compensations.salaryModal.hourly.alert.description'
                    )
                "
            />
        </template>
        <!-- Salary -->
        <template v-else>
            <!-- Hours -->
            <div class="flex items-end">
                <form-number-input
                    class="[&_input]:rounded-r-none"
                    v-model="editedSalary.finalAmount"
                    :label="
                        $t(
                            'employeesPayroll.compensations.salaryModal.salary.salaryLabel'
                        )
                    "
                    size="sm"
                />
                <div
                    class="flex items-center rounded-r-lg border border-l-0 border-gray-300 px-4 py-2.5 text-sm"
                >
                    {{ currency.iso }}
                </div>
            </div>

            <!-- Alert -->
            <base-alert
                class="my-2.5"
                variant="primary"
                :title="
                    $t(
                        'employeesPayroll.compensations.salaryModal.salary.alert.title'
                    )
                "
                :description="
                    $t(
                        'employeesPayroll.compensations.salaryModal.salary.alert.description'
                    )
                "
            />
        </template>

        <!-- Total -->
        <div
            class="flex justify-between gap-3 rounded-lg bg-gray-50 px-2.5 py-5 text-lg font-semibold"
        >
            <span>
                {{ $t('employeesPayroll.compensations.salaryModal.total') }}
            </span>

            <span>
                {{
                    $filters.symbolCurrency(editedSalary.finalAmount, currency)
                }}
            </span>
        </div>

        <!-- Footer -->
        <template #modal-footer>
            <div class="mt-3 flex gap-3">
                <!-- Cancel button -->
                <base-button
                    class="grow justify-center"
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>

                <!-- Edit button -->
                <base-button
                    class="grow justify-center"
                    variant="primary"
                    @click="onEdit"
                >
                    {{
                        $t('employeesPayroll.compensations.salaryModal.editBtn')
                    }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { CURRENCIES } from '@tenant/utils/constants'
const currency = CURRENCIES.eur

const emit = defineEmits(['modal-close', 'modal-edit'])

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },

    employeeName: {
        type: String,
        default: '',
    },

    salary: {
        type: Object,
    },
})

const closeModal = () => {
    emit('modal-close')
}

// Salary values
const editedSalary = ref({})

watch(
    () => props.show,
    () => {
        editedSalary.value = Object.assign({}, props.salary)
    }
)

watch(
    () => [editedSalary.value.amount, editedSalary.value.hours],
    () => {
        if (editedSalary.value.amount && editedSalary.value.hours) {
            editedSalary.value.finalAmount =
                editedSalary.value.amount * editedSalary.value.hours
        }
    }
)

// Edit
const onEdit = () => {
    emit('modal-edit', editedSalary.value)
    closeModal()
}
</script>
