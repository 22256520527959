<template>
    <form-control-group-row class="pb-2">
        <span class="font-medium text-gray-900">
            {{
                $t(
                    'employeesOverview.detail.form.employeeInformation.salaryAndWage'
                )
            }}
        </span>
    </form-control-group-row>

    <form-control-group-row>
        <form-single-select
            v-model="employeePayCalendar"
            :error-message="employeePayCalendarErrorMessage"
            :label="
                $t(
                    'employeesOverview.detail.form.employeeInformation.payCalendar.title'
                )
            "
            :options="payCalendars"
            :disabled="disabled || loadingPayCalendars"
            :hint-text="
                $t(
                    'employeesOverview.detail.form.employeeInformation.payCalendar.description'
                )
            "
        />
    </form-control-group-row>

    <form-control-group-row class="flex-col gap-2.5">
        <employees-detail-information-regular-earning-add-new
            v-if="!regularEarnings?.salary_type"
            @open-modal="openRegularEarningsModal"
        />
        <employees-detail-information-regular-earning-box
            v-else
            :regular-earnings="regularEarnings"
            :is-active="isActive"
            @open-modal="openRegularEarningsModal"
            @on-delete="onDelete"
        />
    </form-control-group-row>

    <employees-detail-information-regular-earning-modal
        v-if="isRegularEarningsModalOpen"
        @modal-close="closeRegularEarningsModal"
        @modal-confirm="onConfirm"
        :show="isRegularEarningsModalOpen"
        :regular-earnings="regularEarnings"
    />
</template>

<script setup>
defineProps({
    disabled: Boolean,
})

const isActive = ref(true)

const { value: regularEarnings } = useField('information')

const { execute: executeGetPayCalendars, loading: loadingPayCalendars } =
    useApi('/api/pay-calendars', 'GET')

const {
    value: employeePayCalendar,
    errorMessage: employeePayCalendarErrorMessage,
} = useField('information.pay_calendar_id')

const isRegularEarningsModalOpen = ref(false)

const payCalendars = ref([])

onMounted(() => {
    executeGetPayCalendars().then((response) => {
        payCalendars.value = response.data.map((payCalendar) => ({
            value: payCalendar.id,
            label: payCalendar.name,
        }))
    })
})

const openRegularEarningsModal = () => {
    isRegularEarningsModalOpen.value = true
}

const closeRegularEarningsModal = () => {
    isRegularEarningsModalOpen.value = false
}

const deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}

const onConfirm = (value) => {
    if (!deepEqual(regularEarnings.value, value)) {
        isActive.value = false
    }

    regularEarnings.value = value
    closeRegularEarningsModal()
}

const onDelete = () => {
    isActive.value = false
    regularEarnings.value = {
        ...regularEarnings.value,
        salary_type: null,
        hourly_rate: 0,
        gross_annual_amount: 0,
        working_pattern: null,
        working_times: {
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0,
        },
        week_working_hours: 0,
        pay_item: null,
        pay_start_date: null,
    }
}
</script>
