<template>
    <div class="flex justify-between">
        <span class="text-sm font-medium text-gray-700">
            {{
                $t(
                    'employeesOverview.detail.form.employeeInformation.regularEarnings.title'
                )
            }}
        </span>

        <span
            class="text-sm font-medium text-primary-700 hover:cursor-pointer"
            @click="onOpenModal"
        >
            {{
                $t(
                    'employeesOverview.detail.form.employeeInformation.regularEarningsChange'
                )
            }}
        </span>
    </div>
    <div
        class="border-1 flex items-start justify-between self-stretch rounded border border-gray-300 p-6"
    >
        <div class="flex flex-col items-start gap-3">
            <span class="text-md font-medium text-gray-900">
                {{
                    $t(
                        'employeesOverview.detail.form.employeeInformation.regularEarnings.values.' +
                            regularEarnings.salary_type +
                            '.label'
                    )
                }}
                <base-badge
                    v-if="isActive"
                    class="ml-2.5"
                    variant="success"
                    :label="$t('general.active')"
                />
                <base-badge
                    v-else
                    variant="warning"
                    :label="$t('general.unsaved')"
                />
            </span>

            <div class="flex flex-col text-xs font-normal text-gray-600">
                <span>
                    {{
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.box.effective',
                            {
                                value: $filters.dateCustomFormat(
                                    regularEarnings.pay_start_date,
                                    'DD MMM YYYY'
                                ),
                            }
                        )
                    }}
                </span>
                <span>
                    {{
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.box.workingPattern',
                            {
                                value: $t(
                                    'employeesOverview.detail.form.employeeInformation.regularEarnings.workingPattern.' +
                                        regularEarnings.working_pattern
                                ),
                            }
                        )
                    }}
                </span>
                <span>
                    {{
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.box.averageHoursPerWeek',
                            {
                                value: regularEarnings.week_working_hours + 'h',
                            }
                        )
                    }}
                </span>
                <span>
                    {{
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.box.basicRatePerHour',
                            {
                                value: $filters.numberFormat(
                                    basicRatePerHour,
                                    4
                                ),
                            }
                        )
                    }}
                </span>
            </div>
        </div>

        <div class="flex items-center justify-end gap-2.5">
            <span class="text-md font-normal text-gray-600">
                {{ $filters.numberFormat(amount) }}
            </span>

            <base-icon
                v-if="isActive"
                variant="grayLight"
                name="line-icons:security:lock-01"
            />

            <base-context-menu v-else class="hover:cursor-pointer">
                <template #trigger="{ toggleMenu }">
                    <div @click="toggleMenu">
                        <base-icon
                            variant="grayLight"
                            name="line-icons:general:dots-vertical"
                        />
                    </div>
                </template>

                <base-context-menu-item @click="onShowEdit">
                    {{ $t('general.edit') }}
                </base-context-menu-item>

                <base-context-menu-item @click="onDelete">
                    {{ $t('general.delete') }}
                </base-context-menu-item>
            </base-context-menu>
        </div>
    </div>
</template>

<script setup>
const emitter = defineEmits(['openModal', 'onDelete'])

const props = defineProps({
    regularEarnings: {
        type: Object,
        required: true,
    },
    isActive: {
        type: Boolean,
        default: true,
    },
})

const basicRatePerHour = computed(() => {
    return props.regularEarnings.salary_type === 'hourly'
        ? props.regularEarnings.hourly_rate
        : props.regularEarnings.gross_annual_amount /
              props.regularEarnings.week_working_hours /
              52
})

const amount = computed(() => {
    return props.regularEarnings.salary_type === 'hourly'
        ? props.regularEarnings.hourly_rate
        : props.regularEarnings.gross_annual_amount
})

const onOpenModal = () => {
    emitter('openModal')
}

const onShowEdit = () => {
    emitter('openModal', props.regularEarnings)
}

const onDelete = () => {
    emitter('onDelete')
}
</script>
