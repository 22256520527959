<template>
    <base-section :title="$t('employeesOverview.create.personalData.headline')">
        <template #right>
            <form-control-group class="rounded-lg border p-6">
                <form-control-group-row>
                    <!-- Salutation -->
                    <div class="w-1/5">
                        <form-single-select
                            v-model="salutation_id"
                            :label="
                                $t(
                                    'employeesOverview.create.personalData.salutation.label'
                                )
                            "
                            :error-message="salutationError"
                            :options="userSalutations"
                            :disabled="disabled"
                        />
                    </div>

                    <!-- Name -->
                    <form-text-input
                        v-model="firstname"
                        :error-message="firstNameError"
                        :label="
                            $t(
                                'employeesOverview.create.personalData.firstName.label'
                            )
                        "
                        :disabled="disabled"
                    />

                    <!-- Last name -->
                    <form-text-input
                        v-model="lastname"
                        :error-message="lastNameError"
                        :label="
                            $t(
                                'employeesOverview.create.personalData.lastName.label'
                            )
                        "
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <!-- Date of birth -->
                    <form-date-picker
                        v-model="dateOfBirth"
                        single
                        :error-message="dateOfBirthError"
                        :label="
                            $t(
                                'employeesOverview.create.personalData.dateOfBirth.label'
                            )
                        "
                        :disabled="disabled"
                    />

                    <!-- Nationality -->
                    <form-single-select
                        v-model="countryId"
                        :error-message="countryIdError"
                        :options="countries"
                        :label="
                            $t(
                                'employeesOverview.create.personalData.nationality.label'
                            )
                        "
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <!-- Address -->
                    <form-text-input
                        v-model="street"
                        :error-message="streetError"
                        :label="
                            $t(
                                'employeesOverview.create.personalData.address.label'
                            )
                        "
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <!-- Postcode -->
                    <form-text-input
                        v-model="postcode"
                        :error-message="postcodeError"
                        :label="
                            $t(
                                'employeesOverview.create.personalData.postcode.label'
                            )
                        "
                        :disabled="disabled"
                    />
                    <!-- City -->
                    <form-text-input
                        v-model="city"
                        :error-message="cityError"
                        :label="
                            $t(
                                'employeesOverview.create.personalData.city.label'
                            )
                        "
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <!-- Country -->
                    <form-text-input
                        v-model="country"
                        :label="
                            $t(
                                'employeesOverview.create.personalData.country.label'
                            )
                        "
                        :tooltip="
                            $t(
                                'employeesOverview.create.personalData.country.tooltip'
                            )
                        "
                        disabled
                    />
                </form-control-group-row>
            </form-control-group>
        </template>
    </base-section>
</template>

<script setup>
import { useCountries } from '@tenant/composables'

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { countries } = useCountries()

const { value: salutation_id, errorMessage: salutationError } =
    useField('salutation_id')
const { value: firstname, errorMessage: firstNameError } = useField('firstname')
const { value: lastname, errorMessage: lastNameError } = useField('lastname')
const { value: countryId, errorMessage: countryIdError } =
    useField('country_id')
const { value: dateOfBirth, errorMessage: dateOfBirthError } = useField(
    'private_information.date_of_birth'
)
const { value: street, errorMessage: streetError } = useField('street')
const { value: postcode, errorMessage: postcodeError } = useField('postcode')
const { value: city, errorMessage: cityError } = useField('city')

const { execute: executeGetSalutations } = useApi('/api/salutations', 'GET')

const userSalutations = ref([])
const country = ref('Cyprus')

onMounted(() => {
    executeGetSalutations().then((response) => {
        userSalutations.value = response.map((salutation) => ({
            value: salutation.id,
            label: salutation.name,
        }))
    })
})
</script>
