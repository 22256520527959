<template>
    <div class="flex h-full">
        <div class="flex w-72 flex-col border-r border-gray-200 bg-gray-25">
            <employees-detail-sidebar :title="title" />
        </div>

        <div class="flex flex-1 flex-col overflow-auto">
            <base-heading class="px-6 py-4 !pb-2.5" :title="titleSection">
                <template #right>
                    <div class="flex gap-3">
                        <base-button
                            variant="primary"
                            :loading="loadingSave || loadingGet"
                            @click="onSave"
                        >
                            {{ $t('general.save') }}
                        </base-button>
                    </div>
                </template>
            </base-heading>

            <router-view :key="key" />
        </div>
    </div>
</template>
<script setup>
import jsonToFormData from 'json-form-data'
import { useNotification } from '@tenant/composables'

const { t } = useI18n()
const route = useRoute()
const key = ref(0)
const { setValues, setErrors, handleSubmit } = useForm()
const { successNotify } = useNotification()
const { errorNotify } = useNotification()
const { useApiGet, useApiSave } = useApiFactory('employees')
const { loading: loadingGet, execute: executeGet } = useApiGet()
const { loading: loadingSave, execute: executeSave } = useApiSave()
const emitter = useEmitter()
useHead({
    title: t('employeesOverview.edit.title'),
})
const title = ref('')

onMounted(async () => {
    getEmployee()
})

const getEmployee = async () => {
    try {
        emitter.emit('set-loading', true)

        const employee = await executeGet(route.params.id)
        title.value = `${employee.firstname} ${employee.lastname}`

        let values = {
            ...employee,
        }

        if (isRoute('employees.overview.edit.information')) {
            values = {
                ...employee,
                information: {
                    working_times: {
                        monday: null,
                        tuesday: null,
                        wednesday: null,
                        thursday: null,
                        friday: null,
                        saturday: null,
                        sunday: null,
                        ...(employee.information?.working_times ?? {}),
                    },
                    working_pattern: 'custom',
                    pay_item: 'regular_hours',
                    week_working_hours: 0,
                    number: '',
                    start_date: '',
                    social_insurance_number: '',
                    social_insurance_category: '',
                    pay_calendar_id: '',
                    ...employee.information,
                },
            }
        }

        setValues({
            ...values,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}

const onSave = handleSubmit(async (values) => {
    try {
        emitter.emit('set-loading', true)

        await executeSave(jsonToFormData(values), route.params.id, {
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'POST',
        })

        key.value++

        successNotify({
            title: t('general.success'),
            text: t('employeesOverview.edit.editSuccess'),
        })
    } catch ({ errors }) {
        setErrors(errors)

        errorNotify({
            title: t('general.error'),
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const titleSection = computed(() => {
    if (isRoute('employees.overview.edit.detail')) {
        return t('employeesOverview.detail.sidebar.personalDetails')
    } else if (isRoute('employees.overview.edit.information')) {
        return t('employeesOverview.detail.sidebar.employeeInformation')
    } else if (isRoute('employees.overview.edit.taxes')) {
        return t('employeesOverview.detail.sidebar.taxes')
    }

    return ''
})

const isRoute = (routeName) => {
    return route.matched.some((i) => i.name === routeName)
}

watch(
    () => route.name,
    () => getEmployee()
)
</script>
