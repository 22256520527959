<template>
    <base-section
        :title="$t('employeesOverview.create.socialSecurity.headline')"
    >
        <template #right>
            <form-control-group>
                <form-control-group-row>
                    <!-- Social insurance number -->
                    <form-text-input
                        v-model="socialInsuranceNumber"
                        :error-message="socialInsuranceNumberError"
                        :label="
                            $t(
                                'employeesOverview.create.socialSecurity.socialInsuranceNumber.label'
                            )
                        "
                        :disabled="disabled"
                    />

                    <!-- Yellow slip ID -->
                    <form-text-input
                        v-model="yellowSlipId"
                        :error-message="yellowSlipIdError"
                        :label="
                            $t(
                                'employeesOverview.create.socialSecurity.yellowSlipId.label'
                            )
                        "
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <!-- Yellow slip document upload -->
                    <div class="w-full">
                        <form-control-label
                            :label="
                                $t(
                                    'employeesOverview.create.socialSecurity.yellowSlipDocumentUpload.label'
                                )
                            "
                        />
                        <base-alert
                            v-if="errorDocument"
                            class="mb-5"
                            variant="danger"
                            :title="$t('general.error')"
                            :description="errorDocument"
                        />
                        <form-file-upload
                            v-model="document"
                            :description="
                                $t(
                                    'employeesOverview.create.socialSecurity.yellowSlipDocumentUpload.description'
                                )
                            "
                            :accepted-files="['application/pdf', 'image/*']"
                            :maxFiles="1"
                            :custom-path="customFilePath"
                            :custom-name="customFileName"
                        />
                    </div>
                </form-control-group-row>
            </form-control-group>
        </template>
    </base-section>
</template>

<script setup>
defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const {
    value: socialInsuranceNumber,
    errorMessage: socialInsuranceNumberError,
} = useField('private_information.social_insurance_no')

const { value: yellowSlipId, errorMessage: yellowSlipIdError } = useField(
    'private_information.yellow_slip_identify'
)

const { value: document, errorMessage: errorDocument } = useField('document')

const customFileName = (file) => {
    return file.full_name
}

const customFilePath = (file) => {
    return file.preview?.path
}
</script>
