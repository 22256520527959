<template>
    <base-section :title="$t('employeesOverview.create.entry.headline')">
        <template #right>
            <form-control-group>
                <form-control-group-row>
                    <!-- Date of entry -->
                    <form-date-picker
                        v-model="dateOfEntry"
                        single
                        :error-message="dateOfEntryError"
                        :label="
                            $t(
                                'employeesOverview.create.entry.dateOfEntry.label'
                            )
                        "
                        :disabled="disabled"
                    />

                    <!-- Date of exit -->
                    <form-date-picker
                        v-model="dateOfExit"
                        single
                        :error-message="dateOfExitError"
                        :label="
                            $t(
                                'employeesOverview.create.entry.dateOfExit.label'
                            )
                        "
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <base-alert
                    v-if="dateOfEntry && showAlert(dateOfEntry)"
                    :title="$t('employeesOverview.create.entry.alert.headline')"
                    :description="
                        $t('employeesOverview.create.entry.alert.description', {
                            month: currentDate.toLocaleString('default', {
                                month: 'long',
                            }),
                            year: currentDate.getFullYear(),
                        })
                    "
                    variant="primary"
                />
            </form-control-group>
        </template>
    </base-section>
</template>

<script setup>
defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { value: dateOfEntry, errorMessage: dateOfEntryError } = useField(
    'work_information.entry_date'
)
const { value: dateOfExit, errorMessage: dateOfExitError } = useField(
    'work_information.exit_date'
)

const currentDate = new Date()

const showAlert = (entryDate) => {
    let months

    months = (currentDate.getFullYear() - entryDate.slice(0, 4)) * 12
    months -= entryDate.slice(5, 7)
    months += currentDate.getMonth() + 1

    return months <= 0
}
</script>
