<template>
    <form-control-group-row>
        <form-single-select
            v-model="newRegularEarning.working_pattern"
            :label="
                $t(
                    'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.workPatternTemplate'
                )
            "
            :options="employeeWorkPatternTemplateOptions"
            :disabled="disabled"
            @change="onChangeWorkPatternTemplate"
        />
    </form-control-group-row>

    <form-control-group-row class="flex-col gap-y-2.5">
        <div class="flex items-center justify-between gap-x-4">
            <form-text-input
                v-model="workingTimeForDisplaying.monday"
                :precision="1"
                :label="$t('employeesOverview.workingHoursModal.monday.label')"
                size="sm"
                @change="onChangeTime($event.target.value, 'monday')"
            />

            <form-text-input
                v-model="workingTimeForDisplaying.tuesday"
                :precision="1"
                :label="$t('employeesOverview.workingHoursModal.tuesday.label')"
                size="sm"
                @change="onChangeTime($event.target.value, 'tuesday')"
            />

            <form-text-input
                v-model="workingTimeForDisplaying.wednesday"
                :precision="1"
                :label="
                    $t('employeesOverview.workingHoursModal.wednesday.label')
                "
                size="sm"
                @change="onChangeTime($event.target.value, 'wednesday')"
            />

            <form-text-input
                v-model="workingTimeForDisplaying.thursday"
                :precision="1"
                :label="
                    $t('employeesOverview.workingHoursModal.thursday.label')
                "
                size="sm"
                @change="onChangeTime($event.target.value, 'thursday')"
            />

            <form-text-input
                v-model="workingTimeForDisplaying.friday"
                :precision="1"
                :label="$t('employeesOverview.workingHoursModal.friday.label')"
                size="sm"
                @change="onChangeTime($event.target.value, 'friday')"
            />

            <form-text-input
                v-model="workingTimeForDisplaying.saturday"
                :precision="1"
                :label="
                    $t('employeesOverview.workingHoursModal.saturday.label')
                "
                size="sm"
                @change="onChangeTime($event.target.value, 'saturday')"
            />

            <form-text-input
                v-model="workingTimeForDisplaying.sunday"
                :precision="1"
                :label="$t('employeesOverview.workingHoursModal.sunday.label')"
                size="sm"
                @change="onChangeTime($event.target.value, 'sunday')"
            />

            <form-number-input
                v-model="week"
                :precision="1"
                :label="$t('employeesOverview.workingHoursModal.week')"
                size="sm"
                disabled
            />
        </div>
        <span class="text-sm font-normal text-gray-500">
            {{
                $t(
                    'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.workPatternDescription'
                )
            }}
        </span>
    </form-control-group-row>
</template>

<script setup>
const { t } = useI18n()
const emitter = defineEmits(['change'])

const props = defineProps({
    regularEarnings: {
        type: Object,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const newRegularEarning = ref({
    working_pattern: 'custom',
    working_times: {
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
        ...props.regularEarnings.working_times,
    },
    week_working_hours: 0,
    ...props.regularEarnings,
})

const employeeWorkPatternTemplateOptions = ref([
    {
        label: t(
            'employeesOverview.detail.form.employeeInformation.regularEarnings.workingPattern.custom'
        ),
        value: 'custom',
    },
    {
        label: t(
            'employeesOverview.detail.form.employeeInformation.regularEarnings.workingPattern.full_time'
        ),
        value: 'full_time',
    },
])

const workingTimeForDisplaying = ref({
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: '',
})

const week = computed(() => {
    return (
        newRegularEarning.value.working_times.monday +
        newRegularEarning.value.working_times.tuesday +
        newRegularEarning.value.working_times.wednesday +
        newRegularEarning.value.working_times.thursday +
        newRegularEarning.value.working_times.friday +
        newRegularEarning.value.working_times.saturday +
        newRegularEarning.value.working_times.sunday
    )
})

onMounted(() => {
    setWorkTime(newRegularEarning.value.working_pattern)
    setWorkTimeDisplay()
})

const setWorkTimeDisplay = () => {
    workingTimeForDisplaying.value.monday = parseToDatetimeFormat(
        newRegularEarning.value.working_times.monday
    )
    workingTimeForDisplaying.value.tuesday = parseToDatetimeFormat(
        newRegularEarning.value.working_times.tuesday
    )
    workingTimeForDisplaying.value.wednesday = parseToDatetimeFormat(
        newRegularEarning.value.working_times.wednesday
    )
    workingTimeForDisplaying.value.thursday = parseToDatetimeFormat(
        newRegularEarning.value.working_times.thursday
    )
    workingTimeForDisplaying.value.friday = parseToDatetimeFormat(
        newRegularEarning.value.working_times.friday
    )
    workingTimeForDisplaying.value.saturday = parseToDatetimeFormat(
        newRegularEarning.value.working_times.saturday
    )
    workingTimeForDisplaying.value.sunday = parseToDatetimeFormat(
        newRegularEarning.value.working_times.sunday
    )
}

const onChangeWorkPatternTemplate = (value) => {
    setWorkTime(value)
    setWorkTimeDisplay()
}

const setWorkTime = (value) => {
    if (value === 'full_time') {
        newRegularEarning.value.working_times.monday = 8
        newRegularEarning.value.working_times.tuesday = 8
        newRegularEarning.value.working_times.wednesday = 8
        newRegularEarning.value.working_times.thursday = 8
        newRegularEarning.value.working_times.friday = 8
        newRegularEarning.value.working_times.saturday = 0
        newRegularEarning.value.working_times.sunday = 0
    }
}

const onChangeTime = (value, day) => {
    const regex = /^(?:\d+|\d+\.\d+|\d+:\d+|\d+h\d+m)$/
    if (!regex.test(value)) {
        workingTimeForDisplaying.value[day] = ''
    }

    value = convertToFloat(value)

    switch (day) {
        case 'monday':
            newRegularEarning.value.working_times.monday = value
            break
        case 'tuesday':
            newRegularEarning.value.working_times.tuesday = value
            break
        case 'wednesday':
            newRegularEarning.value.working_times.wednesday = value
            break
        case 'thursday':
            newRegularEarning.value.working_times.thursday = value
            break
        case 'friday':
            newRegularEarning.value.working_times.friday = value
            break
        case 'saturday':
            newRegularEarning.value.working_times.saturday = value
            break
        case 'sunday':
            newRegularEarning.value.working_times.sunday = value
            break
        default:
            break
    }

    workingTimeForDisplaying.value[day] = parseToDatetimeFormat(value)

    if (
        newRegularEarning.value.working_pattern === 'full_time' &&
        value !== 8
    ) {
        newRegularEarning.value.working_pattern = 'custom'
    }
}

const convertToFloat = (input) => {
    if (input.includes(':')) {
        // If input contains colon (HH:MM format), convert to float
        const [hours, minutes] = input.split(':').map(Number)
        return hours + minutes / 60
    } else if (input.includes('h') && input.includes('m')) {
        // If input contains 'h' and 'm' (8h15m format), convert to float
        const [hours, minutes] = input.split(/[hm]/).map(Number)
        return hours + minutes / 60
    } else {
        // Otherwise, parse as float directly
        return parseFloat(input)
    }
}

const parseToDatetimeFormat = (value) => {
    if (value === 0) {
        return ''
    }

    const floatTime = parseFloat(value)
    if (!isNaN(floatTime)) {
        const hours = Math.floor(floatTime)
        const minutes = Math.round((floatTime - hours) * 60)

        return `${hours}h${minutes}m`
    } else {
        return ''
    }
}

watch(
    () => week.value,
    (value) => {
        newRegularEarning.value.week_working_hours = value
    },
    { immediate: true }
)

watch(
    () => newRegularEarning.value,
    (value) => {
        emitter('change', value)
    },
    { deep: true }
)
</script>
