<template>
    <base-modal close :show="show" size="xl" @modal-close="closeModal">
        <template #modal-header>
            <div class="gap-1">
                <h4 class="text-lg font-semibold text-gray-900">
                    {{
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.title'
                        )
                    }}
                </h4>
                <span class="text-sm font-normal text-gray-600">
                    {{
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.description'
                        )
                    }}
                </span>
            </div>
        </template>

        <hr class="-mx-6" />

        <form-control-group class="pt-6">
            <form-control-group-row>
                <form-radio-group-item
                    v-model="newRegularEarning.salary_type"
                    name="employeeSalaryType"
                    value="annual"
                    bullet-position="left"
                    bullet-icon=""
                >
                    <template #default="{ checked }">
                        <div class="text-sm">
                            <p
                                class="font-medium"
                                :class="
                                    checked
                                        ? 'text-primary-800'
                                        : 'text-gray-700'
                                "
                            >
                                {{
                                    $t(
                                        'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.annualSalary'
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                </form-radio-group-item>
                <form-radio-group-item
                    v-model="newRegularEarning.salary_type"
                    name="employeeSalaryType"
                    value="hourly"
                    bullet-position="left"
                    bullet-icon=""
                >
                    <template #default="{ checked }">
                        <div class="text-sm">
                            <p
                                class="font-medium"
                                :class="
                                    checked
                                        ? 'text-primary-800'
                                        : 'text-gray-700'
                                "
                            >
                                {{
                                    $t(
                                        'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.hourly'
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                </form-radio-group-item>
            </form-control-group-row>

            <form-control-group-row>
                <form-number-input
                    v-if="newRegularEarning.salary_type === 'hourly'"
                    class="w-1/2"
                    :precision="2"
                    :block="false"
                    v-model="newRegularEarning.hourly_rate"
                    :label="
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.hourlyRate'
                        )
                    "
                    :disabled="disabled"
                />

                <form-number-input
                    v-if="newRegularEarning.salary_type === 'annual'"
                    v-model="newRegularEarning.gross_annual_amount"
                    :precision="2"
                    :label="
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.grossAnnualAmount'
                        )
                    "
                    :hint-text="
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.contractedAmount'
                        )
                    "
                    :disabled="disabled"
                />

                <form-number-input
                    :model-value="basicRatePerHour"
                    v-if="newRegularEarning.salary_type === 'annual'"
                    :precision="6"
                    :label="
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.basicRatePerHour'
                        )
                    "
                    :hint-text="
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.calculatedUseWorkPattern'
                        )
                    "
                    disabled="disabled"
                />
            </form-control-group-row>

            <hr class="-mx-6 my-2" />

            <employees-detail-information-regular-earning-modal-working-time
                @change="onChangeWorkingTimes"
                :regular-earnings="newRegularEarning"
                :disabled="disabled"
            />

            <hr class="-mx-6 my-2" />

            <form-control-group-row>
                <form-single-select
                    v-model="newRegularEarning.pay_item"
                    :label="
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.earningPayItem'
                        )
                    "
                    :options="employeeEarningPayItemOptions"
                    :disabled="disabled"
                />
                <form-date-picker
                    required
                    v-model="newRegularEarning.pay_start_date"
                    single
                    :label="
                        $t(
                            'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.startDate'
                        )
                    "
                    :disabled="disabled"
                />
            </form-control-group-row>
        </form-control-group>

        <!-- Footer -->
        <template #modal-footer>
            <div class="mt-3 flex justify-end gap-3">
                <!-- Cancel button -->
                <base-button outline variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>

                <!-- Add button -->
                <base-button variant="primary" @click="onConfirm">
                    {{ $t('general.confirm') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const { t } = useI18n()
const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    regularEarnings: {
        type: Object,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const newRegularEarning = ref({
    salary_type: 'annual',
    hourly_rate: 0,
    gross_annual_amount: 0,
    working_pattern: '',
    working_times: {
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
        ...(props.regularEarnings?.working_times ?? {}),
    },
    week_working_hours: 0,
    pay_item: 'regular_hours',
    pay_start_date: '',
    ...props.regularEarnings,
})

const employeeEarningPayItemOptions = ref([
    {
        label: t(
            'employeesOverview.detail.form.employeeInformation.regularEarnings.modal.earningPayItemOptions.regular_hours'
        ),
        value: 'regular_hours',
    },
])

const emit = defineEmits(['modal-close', 'modal-confirm'])

const closeModal = () => {
    emit('modal-close')
}

const onConfirm = () => {
    emit('modal-confirm', newRegularEarning.value)
}

const basicRatePerHour = computed(() => {
    return (
        newRegularEarning.value.gross_annual_amount /
        52 /
        newRegularEarning.value.week_working_hours
    )
})

const onChangeWorkingTimes = (value) => {
    newRegularEarning.value.working_times = value.working_times
    newRegularEarning.value.week_working_hours = value.week_working_hours
    newRegularEarning.value.working_pattern = value.working_pattern
}
</script>
