<template>
    <base-sticky-heading class="min-h-[82px] items-center">
        <template #title>
            <div class="flex flex-col gap-1">
                <div class="flex gap-x-2 gap-y-1">
                    <div class="text-lg font-medium text-gray-900">
                        {{ $t('employeesOverview.list.headline') }}
                    </div>

                    <base-badge
                        :label="
                            $t(
                                'employeesOverview.list.headlineBadge',
                                employees.total
                            )
                        "
                    />
                </div>

                <div class="text-sm font-normal text-gray-500">
                    {{ $t('employeesOverview.list.description') }}
                </div>
            </div>
        </template>

        <template #right>
            <div class="flex flex-col gap-x-3 gap-y-1 xl:flex-row">
                <base-button
                    size="md"
                    variant="primary"
                    icon="line-icons:general:plus"
                    @click="openAddModal"
                >
                    {{ $t('employeesOverview.list.addEmployee') }}
                </base-button>
            </div>
        </template>

        <data-grid
            :data-source="employees.data"
            :columns="columns"
            :loading="loadingSearch"
            :empty-text="$t('employeesOverview.list.emptyText')"
            :empty-sub="$t('employeesOverview.list.emptySubText')"
            identify-field="id"
            @row-click="redirectToDetail"
            table-borderless
        >
            <template #filter>
                <div class="flex w-full items-center justify-between gap-2">
                    <div class="grow">
                        <base-button-group>
                            <base-button variant="default">
                                {{
                                    $t('employeesOverview.list.filter.viewAll')
                                }}
                            </base-button>

                            <base-button variant="default">
                                {{
                                    $t(
                                        'employeesOverview.list.filter.newlyHired'
                                    )
                                }}
                            </base-button>

                            <base-button variant="default">
                                {{
                                    $t(
                                        'employeesOverview.list.filter.absentToday'
                                    )
                                }}
                            </base-button>

                            <base-button variant="default">
                                {{
                                    $t('employeesOverview.list.filter.archived')
                                }}
                            </base-button>
                        </base-button-group>
                    </div>

                    <div class="flex flex-1 gap-x-3 gap-y-1">
                        <employees-overview-search-bar />

                        <base-button
                            variant="default"
                            icon="line-icons:general:filter-lines"
                        >
                            {{ $t('general.filters') }}
                        </base-button>
                    </div>
                </div>
            </template>

            <template #column-name="{ item }">
                <users-avatar-group :user="item" />
            </template>

            <template
                v-if="!$feature.isActive('FEATURE_FLAG_427')"
                #column-tags="{ item }"
            >
                <base-badge
                    v-for="tag in item.tags"
                    size="sm"
                    :key="tag.id"
                    :label="tag"
                />
            </template>

            <template
                v-if="$feature.isActive('FEATURE_FLAG_427')"
                #column-entry_date="{ item }"
            >
                {{
                    item?.work_information?.entry_date
                        ? formatDate(item?.work_information?.entry_date)
                        : ''
                }}
            </template>

            <template
                v-if="$feature.isActive('FEATURE_FLAG_427')"
                #column-employee_type="{ item }"
            >
                {{ item?.contract?.contract_type?.name }}
            </template>

            <template
                v-if="$feature.isActive('FEATURE_FLAG_427')"
                #column-vacation="{ item }"
            >
                {{
                    item?.vacation_information?.days_taken &&
                    item?.vacation_information?.annual_leave_days_in_year
                        ? $t('employeesOverview.list.vacation_taken.content', {
                              taken: item?.vacation_information?.days_taken,
                              total: item?.vacation_information
                                  ?.annual_leave_days_in_year,
                          })
                        : $t('employeesOverview.vacationModal.title')
                }}
            </template>

            <template
                v-if="$feature.isActive('FEATURE_FLAG_427')"
                #column-payment="{ item }"
            >
                {{
                    item?.contract?.payroll_type === 'salary'
                        ? $t(
                              'employeesOverview.create.compensation.payroll.optionSalary'
                          )
                        : $t(
                              'employeesOverview.create.compensation.payroll.optionHourlyRate'
                          )
                }}
            </template>

            <template #column-action="{ item }">
                <base-context-menu>
                    <base-context-menu-item
                        icon="line-icons:general:edit-01"
                        @click="redirectToEdit(item)"
                    >
                        {{ $t('general.edit') }}
                    </base-context-menu-item>

                    <base-context-menu-item
                        icon="line-icons:general:archive"
                        @click="archiveEmployee(item)"
                    >
                        {{ $t('general.archive') }}
                    </base-context-menu-item>

                    <base-context-menu-item
                        icon="line-icons:general:trash-01"
                        @click="setEntity(item)"
                    >
                        {{ $t('general.delete') }}
                    </base-context-menu-item>
                </base-context-menu>
            </template>

            <template #footer>
                <grid-pagination :total="employees.last_page" :page="1" />
            </template>
        </data-grid>
    </base-sticky-heading>

    <base-delete-modal
        :loading="loadingDelete"
        :show="deletingEmployee?.id"
        :name="deletingEmployee?.firstname + ' ' + deletingEmployee?.lastname"
        :confirmation-message="$t('employeesOverview.list.deleteConfirmation')"
        :confirmation-value="
            deletingEmployee?.firstname + ' ' + deletingEmployee?.lastname
        "
        @modal-close="setEntity(null)"
        @confirm-delete="confirmDelete"
    />

    <employees-overview-add-employee-modal
        v-if="showAddModal"
        :show="showAddModal"
        @modal-close="closeAddModal"
        @refresh-employees="executeRefreshSearch"
    />
</template>

<script setup>
const $feature = inject('$feature')

const { t } = useI18n()
const router = useRouter()

const {
    entity: deletingEmployee,
    entities: employees,
    setEntity,
    useApiSearch,
    useApiDelete,
} = useApiFactory('employees')

const {
    loading: loadingSearch,
    execute: executeSearch,
    executeRefreshSearch,
} = useApiSearch()
const { loading: loadingDelete, execute: executeDelete } = useApiDelete()

const { successNotify } = useNotification()

const showAddModal = ref(false)

const columns = $feature.isActive('FEATURE_FLAG_427')
    ? [
          {
              property: 'name',
              label: t('employeesOverview.list.employeePerson'),
          },
          {
              property: 'entry_date',
              label: t('employeesOverview.create.entry.dateOfEntry.label'),
          },
          {
              property: 'employee_type',
              label: t(
                  'employeesOverview.create.contractData.employedAs.label'
              ),
          },
          {
              property: 'vacation',
              label: t('employeesOverview.list.vacation_taken.label'),
          },
          {
              property: 'payment',
              label: t('employeesOverview.list.payment.label'),
          },
      ]
    : [
          {
              property: 'name',
              label: t('employeesOverview.list.employeePerson'),
          },
          {
              property: 'phoneNumber',
              label: t('employeesOverview.list.phoneNumber'),
              headerCellClass: 'hidden xl:table-cell',
              dataCellClass: 'hidden xl:table-cell',
          },
          {
              property: 'department',
              label: t('employeesOverview.list.department'),
          },
          {
              property: 'tags',
              label: t('employeesOverview.list.tags'),
              headerCellClass: 'hidden xl:table-cell',
              dataCellClass: 'hidden xl:table-cell',
          },
      ]

const openAddModal = () => {
    showAddModal.value = true
}

const closeAddModal = () => {
    showAddModal.value = false
}
const confirmDelete = () => {
    executeDelete().then(() => {
        successNotify({
            title: t('general.success'),
            text: t('employeesOverview.list.deleteSuccess'),
        })

        executeSearch()
    })
}

const formatDate = (inputDateStr) => {
    const inputDate = new Date(inputDateStr)

    const day = String(inputDate.getDate()).padStart(2, '0')
    const month = String(inputDate.getMonth() + 1).padStart(2, '0')
    const year = inputDate.getFullYear()

    return `${day}.${month}.${year}`
}

const redirectToEdit = (item) => {
    router.push({
        name: 'employees.overview.edit',
        params: { id: item.id },
    })
}

const redirectToDetail = (id) => {
    router.push({ name: 'employees.overview.edit', params: { id } })
}
</script>
