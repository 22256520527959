<template>
    <form-control-group>
        <div>
            <!-- For -->
            <p class="mb-2.5 text-sm">
                {{
                    $t(
                        'employeesPayroll.compensations.addCompensationModal.forLabel'
                    )
                }}
                {{ employeeName }}
            </p>

            <p class="text-sm">
                {{
                    $t(
                        'employeesPayroll.compensations.addCompensationModal.holidaySupplement150.text'
                    )
                }}
            </p>
        </div>

        <form-control-group-row class="items-end">
            <!-- Hours -->
            <div class="w-1/2">
                <form-number-input
                    v-model="compensationHours"
                    :label="
                        $t(
                            'employeesPayroll.compensations.addCompensationModal.holidaySupplement150.hours.label'
                        )
                    "
                    size="sm"
                    @change="onChange()"
                />
            </div>

            <!-- Hourly Rate -->
            <div class="flex w-1/2 items-end">
                <form-number-input
                    class="[&_input]:rounded-r-none"
                    v-model="compensationHourlyRate"
                    :label="
                        $t(
                            'employeesPayroll.compensations.addCompensationModal.holidaySupplement150.hourlyRate.label'
                        )
                    "
                    size="sm"
                    @change="onChange()"
                />
                <div
                    class="flex items-center rounded-r-lg border border-l-0 border-gray-300 px-4 py-2.5 text-sm"
                >
                    {{ currency.iso }}
                </div>
            </div>

            <!-- Percentage -->
            <div class="shrink-0 text-sm font-medium">
                {{
                    $t(
                        'employeesPayroll.compensations.addCompensationModal.holidaySupplement150.percents'
                    )
                }}
            </div>
        </form-control-group-row>

        <!-- Total -->
        <div
            class="flex justify-between gap-3 rounded-lg bg-gray-50 px-2.5 py-5 text-lg font-semibold"
        >
            <span>
                {{
                    $t(
                        'employeesPayroll.compensations.addCompensationModal.holidaySupplement150.totalLabel'
                    )
                }}
            </span>

            <span>
                {{ $filters.symbolCurrency(total, currency) }}
            </span>
        </div>
    </form-control-group>
</template>

<script setup>
import { CURRENCIES } from '@tenant/utils/constants'

const currency = CURRENCIES.eur
const total = ref(0)

const { value: compensationHours } = useField('compensationHours')
const { value: compensationHourlyRate } = useField('compensationHourlyRate')

defineProps({
    employeeName: {
        type: String,
    },
})

const onChange = () => {
    if (compensationHours.value && compensationHourlyRate.value) {
        total.value = compensationHours.value * compensationHourlyRate.value
        const percentage = total.value * 0.5
        total.value += percentage
    }
}
</script>
