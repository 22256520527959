<template>
    <base-page-header :title="$t('employeesOverview.create.title')" />

    <employees-overview-create-personal-data :disabled="loading" />
    <employees-overview-create-entry :disabled="loading" />
    <employees-overview-create-contract-data :disabled="loading" />
    <employees-overview-create-vacation :disabled="loading" />
    <employees-overview-create-compensation :disabled="loading" />
    <employees-overview-create-social-security :disabled="loading" />

    <div class="flex justify-end gap-5 pt-5">
        <base-button outline variant="default" @click="onCancel">
            {{ $t('general.cancel') }}
        </base-button>

        <base-button
            variant="primary"
            icon="line-icons:general:check"
            @click="onSave"
            :loading="loading"
        >
            {{ $t('general.save') }}
        </base-button>
    </div>
</template>

<script setup>
import jsonToFormData from 'json-form-data'

const { t } = useI18n()
const router = useRouter()

const initialValues = {
    salutation_id: null,
    lastname: '',
    firstname: '',
    hr_employee_type_id: null,
    street: null,
    postcode: null,
    city: null,
    country_id: null,
    private_information: {
        is_using_bank_transfer: false,
        social_insurance_no: null,
        yellow_slip_identify: null,
        bank_account_number: null,
        bank_account_name: null,
        date_of_birth: null,
    },
    work_information: {
        entry_date: null,
        exit_date: null,
        is_working_holiday: false,
        weekly_working_hours: 0,
        weekly_working_times: {
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
            sunday: null,
        },
    },
    vacation_information: {
        min_days: null,
        annual_leave_days_in_year: null,
        days_taken: null,
        annual_leave_days_actual: null,
        calculated_annual_leave_days_actual: null,
    },
    contract: {
        wage: null,
        payroll_type: null,
        is_thirteen_salary: false,
    },
}

const { setErrors, handleSubmit } = useForm({
    initialValues,
})
const { successNotify } = useNotification()

const { loading, execute } = useApiFactory('employees').useApiCreate()

useHead({
    title: t('employeesOverview.create.title'),
})

const emitter = useEmitter()

const onCancel = () => {
    router.push({ name: 'employees.overview.list' })
}

const onSave = handleSubmit(async (values) => {
    try {
        emitter.emit('set-loading', true)

        const employee = await execute(jsonToFormData(values), {
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'POST',
        })

        await router.push({
            name: 'employees.overview.edit',
            params: { id: employee.id },
        })

        successNotify({
            title: t('general.success'),
            text: t('employeesOverview.create.addSuccess'),
        })
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
    }
})
</script>
