<template>
    <!-- Empty -->
    <div
        v-if="!dataSource?.total > 0"
        class="flex items-start justify-between rounded-lg border bg-white px-6 py-5 shadow-sm"
    >
        <div>
            <div class="mb-1 flex items-center gap-x-2 gap-y-1">
                <div class="text-lg font-medium">
                    {{ employee.firstname }} {{ employee.lastname }}
                </div>

                <base-badge
                    :label="
                        $t('employeesPayroll.compensations.table.badge', {
                            year: new Date().getFullYear(),
                            days: vacationTaken.toFixed(1),
                        })
                    "
                />
            </div>

            <div class="text-sm text-gray-500">
                {{
                    $t('employeesPayroll.compensations.table.emptyText', {
                        year: new Date().getFullYear(),
                    })
                }}
            </div>
        </div>

        <base-button
            icon="line-icons:general:plus-circle"
            variant="secondary-color"
            @click="onAddCompensation"
        >
            {{ $t('employeesPayroll.compensations.table.addCompensation') }}
        </base-button>
    </div>
    <!-- Data grid -->
    <data-grid
        v-else
        :data-source="dataSource?.data"
        :columns="columns"
        :loading="loadingSearch"
        :empty-text="
            $t('employeesPayroll.compensations.table.emptyText', {
                year: new Date().getFullYear(),
            })
        "
        :empty-sub="$t('employeesPayroll.compensations.table.emptySubText')"
        class="overflow-hidden shadow-sm [&>div:last-child]:hidden"
    >
        <template #header>
            <div class="flex w-full items-center justify-between gap-2">
                <div class="flex items-center gap-x-2 gap-y-1">
                    <div class="text-lg font-medium">
                        {{ employee.firstname }} {{ employee.lastname }}
                    </div>

                    <base-badge
                        :label="
                            $t('employeesPayroll.compensations.table.badge', {
                                year: new Date().getFullYear(),
                                days: vacationTaken.toFixed(1),
                            })
                        "
                    />
                </div>

                <base-button
                    icon="line-icons:general:plus-circle"
                    variant="secondary-color"
                    @click="onAddCompensation"
                >
                    {{
                        $t(
                            'employeesPayroll.compensations.table.addCompensation'
                        )
                    }}
                </base-button>
            </div>
        </template>

        <template #column-compensation="{ item }">
            {{ item.name }}
        </template>

        <template #column-hours="{ item }">
            <div v-if="item.hours" class="flex">
                <form-number-input
                    class="[&_input]:rounded-r-none"
                    v-model="item.hours"
                    size="sm"
                />
                <div
                    class="flex items-center rounded-r-lg border border-l-0 border-gray-300 px-4 py-2.5 text-sm"
                >
                    {{ $t('employeesPayroll.compensations.table.hoursSuffix') }}
                </div>
            </div>
        </template>

        <template #column-amount="{ item }">
            <span v-if="item.amount">
                {{ $filters.symbolCurrency(item.amount, item.currency) }}
            </span>
        </template>

        <template #column-final-amount="{ item }">
            <span>
                {{ $filters.symbolCurrency(item.finalAmount, item.currency) }}
            </span>
        </template>

        <template #column-action="{ item }">
            <div class="flex gap-1 px-2 py-1">
                <base-button
                    icon="line-icons:general:trash-01"
                    variant="tertiary-gray"
                    size="sm"
                    class="!border-0 !p-2.5"
                    @click="onToggleDeleteCompensation(item)"
                    :disabled="item.type == 'salary'"
                />
                <base-button
                    icon="line-icons:general:edit-01"
                    variant="tertiary-gray"
                    size="sm"
                    class="!border-0 !p-2.5"
                    @click="
                        item.type == 'salary'
                            ? onToggleSalaryModal()
                            : onToggleCompensationModal(item)
                    "
                />
            </div>
        </template>

        <template #footer></template>
    </data-grid>

    <base-delete-modal
        :loading="loadingDelete"
        :show="deletingEmployeeCompensation?.id"
        :name="deletingEmployeeCompensation?.name"
        :confirmation-message="
            $t('employeesPayroll.compensations.deleteConfirmation')
        "
        :confirmation-value="deletingEmployeeCompensation?.name"
        @modal-close="setEntity(null)"
        @confirm-delete="confirmDelete"
    />

    <employees-payroll-compensations-modal
        :show="compensationModalShow"
        :employee-name="`${employee.firstname} ${employee.lastname}`"
        @modal-close="onCompensationModalClose"
    />

    <employees-payroll-salary-modal
        :show="showSalaryModal"
        :employee-name="`${employee.firstname} ${employee.lastname}`"
        :salary="salary"
        @modal-close="onToggleSalaryModal"
        @modal-edit="onEditSalary"
    />
</template>

<script setup>
const { t } = useI18n()
const compensationModalShow = ref(false)
const salary = ref({})

const props = defineProps({
    employee: {
        type: Object,
        required: true,
    },
})

const { useApiSearch, useApiDelete } = useApiFactory('compensations')

const { loading: loadingSearch, execute: executeSearch } = useApiSearch()
const { loading: loadingDelete, execute: executeDelete } = useApiDelete()

const vacationTaken = ref(5.5)
const dataSource = ref([])
const columns = [
    {
        property: 'compensation',
        label: t('employeesPayroll.compensations.table.compensationLabel'),
    },
    {
        property: 'hours',
        label: t('employeesPayroll.compensations.table.hoursLabel'),
    },
    {
        property: 'amount',
        label: t('employeesPayroll.compensations.table.amountLabel'),
    },
    {
        property: 'final-amount',
        label: t('employeesPayroll.compensations.table.finalAmountLabel'),
    },
]

onMounted(async () => {
    await getEmployeesCompensations(props.employee.id)
})

const getEmployeesCompensations = async (employeeId) => {
    executeSearch(employeeId)
        .then((response) => {
            // TODO: Get data from database
            dataSource.value = response
            throw new Error('')
        })
        .catch(() => {
            dataSource.value = {
                total: 2,
                data: [
                    {
                        id: 0,
                        type: 'salary',
                        name: 'Salary',
                        hours: 50,
                        amount: 12,
                        finalAmount: 600,
                        currency: {
                            iso: 'eur',
                            symbol: '€',
                        },
                    },
                    {
                        id: 1,
                        name: 'Bonus',
                        hours: null,
                        amount: null,
                        finalAmount: 20,
                        currency: {
                            iso: 'eur',
                            symbol: '€',
                        },
                    },
                ],
            }

            // Set salary after fetch compensation items
            salary.value = dataSource.value.data[0]
        })
}

const confirmDelete = () => {
    executeDelete().then(() => {
        executeSearch()
    })
}

const onAddCompensation = () => {
    compensationModalShow.value = true
}

const onCompensationModalClose = () => {
    compensationModalShow.value = false
}

// Salary modal
const showSalaryModal = ref(false)

const onToggleSalaryModal = () => {
    showSalaryModal.value = !showSalaryModal.value
}

const onEditSalary = (value) => {
    dataSource.value.data[0] = value
    salary.value = value

    // TODO: Edit in database
}
</script>
