<template>
    <base-modal close :show="show" size="lg" @modal-close="closeModal">
        <!-- Modal header -->
        <template #modal-header>
            <div class="flex items-center gap-1">
                <!-- Return btn -->
                <base-button
                    v-if="modalStep == 2"
                    @click="onReturn"
                    variant="link-gray"
                    :has-padding="false"
                >
                    <base-icon
                        name="line-icons:arrows:chevron-left"
                        variant="inherit"
                        size="lg"
                    />
                </base-button>

                <!-- Modal title -->
                <h4 class="text-lg">
                    {{ modalTitle }}
                </h4>
            </div>
        </template>

        <!-- Search absences -->
        <employees-payroll-absences-modal-search-step
            v-if="modalStep == 1"
            @absence-select="(value) => onSelectAbsence(value)"
        />

        <!-- 2nd step - fields -->
        <div v-if="modalStep == 2">
            <employees-payroll-absences-general-modal
                v-if="newAbsence.type == 'general'"
                :absence="newAbsence"
                @set-absence="setAbsence"
            />
            <employees-payroll-absences-stay-abroad-modal
                v-if="newAbsence.type == 'stay-abroad'"
                :absence="newAbsence"
                :employee-name="employeeName"
                @set-absence="setAbsence"
            />
            <employees-payroll-absences-dual-study-modal
                v-if="newAbsence.type == 'dual-study'"
                :absence="newAbsence"
                :employee-name="employeeName"
                @set-absence="setAbsence"
            />
            <employees-payroll-absences-parental-leave-modal
                v-if="newAbsence.type == 'parental-leave'"
                :absence="newAbsence"
                :employee-name="employeeName"
                @set-absence="setAbsence"
            />
            <employees-payroll-absences-unpaid-leave-modal
                v-if="newAbsence.type == 'unpaid-leave'"
                :absence="newAbsence"
                :employee-name="employeeName"
                @set-absence="setAbsence"
            />
            <employees-payroll-absences-unexcused-modal
                v-if="newAbsence.type == 'unexcused'"
                :absence="newAbsence"
                :employee-name="employeeName"
                @set-absence="setAbsence"
            />
            <employees-payroll-absences-unlawful-strike-modal
                v-if="newAbsence.type == 'unlawful-strike'"
                :absence="newAbsence"
                :employee-name="employeeName"
                @set-absence="setAbsence"
            />
            <employees-payroll-absences-irrevocable-exemption-modal
                v-if="newAbsence.type == 'irrevocable-exemption'"
                :absence="newAbsence"
                :employee-name="employeeName"
                @set-absence="setAbsence"
            />
            <employees-payroll-absences-holiday-modal
                v-if="newAbsence.type == 'holiday'"
                :absence="newAbsence"
                :employee-name="employeeName"
                @set-absence="setAbsence"
            />
        </div>

        <!-- Footer -->
        <template #modal-footer>
            <div class="mt-3 flex justify-end gap-3">
                <!-- Cancel button -->
                <base-button outline variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>

                <!-- Edit button -->
                <base-button
                    v-if="modalStep == 2"
                    outline
                    variant="default"
                    @click="onAddEdit"
                >
                    {{
                        absence
                            ? $t('employeesPayroll.absences.absenceModal.edit')
                            : $t('employeesPayroll.absences.absenceModal.add')
                    }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const { t } = useI18n()
const emit = defineEmits(['modal-close', 'modal-add', 'modal-edit'])

const modalStep = ref(1)
const modalTitle = ref()
const newAbsence = ref(null)

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },

    employeeName: {
        type: String,
        default: '',
    },

    absence: {
        type: Object,
        default: null,
    },
})

watch(
    () => props.show,
    () => {
        if (props.absence) {
            newAbsence.value = Object.assign({}, props.absence)
            modalTitle.value =
                t('employeesPayroll.absences.absenceModal.editTitle') +
                ' ' +
                newAbsence.value.name
            modalStep.value = 2
        } else {
            newAbsence.value = {}
            modalTitle.value = t(
                'employeesPayroll.absences.absenceModal.addTitle'
            )
        }
    }
)

// Select absence type
const onSelectAbsence = (value) => {
    newAbsence.value.type = value.value
    modalStep.value += 1

    if (!props.absence) {
        modalTitle.value =
            t('employeesPayroll.absences.absenceModal.addTitle') +
            ' ' +
            value.label
    }
}

// Return on prev modal step
const onReturn = () => {
    modalStep.value -= 1

    if (!props.absence) {
        modalTitle.value = t('employeesPayroll.absences.absenceModal.addTitle')
    }
}

// Set edited absence values
const setAbsence = (value) => {
    newAbsence.value = value
}

// Add/Edit absence
const onAddEdit = () => {
    let periodStart = newAbsence.value.periodStart
    let periodEnd = newAbsence.value.periodEnd

    if (periodStart.includes('.')) {
        periodStart = revertFormatDate(periodStart)
    }
    if (periodEnd.includes('.')) {
        periodEnd = revertFormatDate(periodEnd)
    }

    const dateDiff = getDateDiffInDays(periodStart, periodEnd)

    // Return if end date is smaller than start date
    if (dateDiff < 0) return

    // Add id if not edit
    if (props.absence) {
        newAbsence.value.id = props.absence.id
    } else {
        newAbsence.value.id = new Date()
    }

    // set new absence values
    newAbsence.value.days += dateDiff
    newAbsence.value.periodStart = formatDate(periodStart)
    newAbsence.value.periodEnd = formatDate(periodEnd)

    if (props.absence) {
        emit('modal-edit', newAbsence.value)
    } else {
        emit('modal-add', newAbsence.value)
    }

    closeModal()
}

// Get difference in days
const getDateDiffInDays = (start, end) => {
    const diffInMs = new Date(end) - new Date(start)
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

    return diffInDays
}

// Format date
const formatDate = (date) => {
    const year = date.substr(0, 4)
    const month = date.substr(5, 2)
    const day = date.substr(8, 2)

    return `${day}.${month}.${year}`
}

// Revert format date
const revertFormatDate = (date) => {
    const year = date.substr(6, 4)
    const month = date.substr(3, 2)
    const day = date.substr(0, 2)

    return `${year}-${month}-${day}`
}

// Close modal
const closeModal = () => {
    modalStep.value = 1
    newAbsence.value = null
    modalTitle.value = null
    emit('modal-close')
}
</script>
