<template>
    <form-control-group-row class="pb-2">
        <span class="font-medium text-gray-900">
            {{
                $t(
                    'employeesOverview.detail.form.employeeInformation.socialInsurance'
                )
            }}
        </span>
    </form-control-group-row>

    <form-control-group-row>
        <form-text-input
            v-model="employeeSocialInsuranceNumber"
            :error-message="employeeSocialInsuranceNumberErrorMessage"
            :label="
                $t(
                    'employeesOverview.detail.form.employeeInformation.socialInsuranceNumber'
                )
            "
            :tooltip="
                $t('employeesOverview.create.personalData.country.tooltip')
            "
            :disabled="disabled"
        />
    </form-control-group-row>

    <form-control-group-row class="flex-col gap-2.5">
        <employees-detail-information-social-insurance-category-select
            v-if="!employeeGhsCategoryId"
            :disabled="disabled"
            :category="employeeGhsCategoryId"
            :error-message="employeeGhsCategoryIdErrorMessage"
            @change="onChangeCategory"
        />

        <employees-detail-information-social-insurance-category-box
            v-else
            :category="employeeGhsCategoryId"
            :is-active="isActive"
            @on-delete="onDelete"
            @on-edit="onEdit"
        />
    </form-control-group-row>

    <employees-detail-information-social-insurance-category-modal
        v-if="showCategoryModal"
        :category="employeeGhsCategoryId"
        :show="showCategoryModal"
        :disabled="disabled"
        @change="onChangeCategory"
        @modal-close="onCloseCategoryModal"
    />
</template>

<script setup>
defineProps({
    disabled: Boolean,
})

const isActive = ref(true)

const {
    value: employeeSocialInsuranceNumber,
    errorMessage: employeeSocialInsuranceNumberErrorMessage,
} = useField('information.social_insurance_number')
const {
    value: employeeGhsCategoryId,
    errorMessage: employeeGhsCategoryIdErrorMessage,
} = useField('information.social_insurance_category')

const showCategoryModal = ref(false)

const onCloseCategoryModal = () => {
    showCategoryModal.value = false
}

const onEdit = () => {
    showCategoryModal.value = true
}
const onDelete = () => {
    isActive.value = false
    employeeGhsCategoryId.value = null
}

const onChangeCategory = (category) => {
    if (employeeGhsCategoryId.value !== category) {
        isActive.value = false
    }

    employeeGhsCategoryId.value = category
}
</script>
