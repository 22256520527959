<template>
    <form-control-group-row>
        <span class="font-medium text-gray-900">
            {{ $t('employeesOverview.detail.form.employeeInformation.title') }}
        </span>
    </form-control-group-row>

    <form-control-group-row>
        <form-text-input
            v-model="employeeNumber"
            :error-message="employeeNumberErrorMessage"
            :label="
                $t('employeesOverview.detail.form.employeeInformation.number')
            "
            :tooltip="
                $t('employeesOverview.create.personalData.country.tooltip')
            "
            :disabled="disabled"
        />

        <form-date-picker
            v-model="employeeStartDate"
            single
            :error-message="employeeStartDateErrorMessage"
            :label="
                $t(
                    'employeesOverview.detail.form.employeeInformation.startDate'
                )
            "
            :disabled="disabled"
        />
    </form-control-group-row>
</template>

<script setup>
defineProps({
    disabled: Boolean,
})

const { value: employeeNumber, errorMessage: employeeNumberErrorMessage } =
    useField('information.number')

const {
    value: employeeStartDate,
    errorMessage: employeeStartDateErrorMessage,
} = useField('information.start_date')
</script>
